import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import { load, deleteData } from '../../redux/ActionsAndReducers/BaseFunction';
import RestaurantTable from '../../components/Table/RestaurantTable';
import { notifyError } from '../../helpers/Notify';
import RestaurantDetails from '../../components/Details/RestaurantDetails';

class Restaurants extends React.Component {
  componentDidMount() {
    const { _loadrestaurants } = this.props;
    _loadrestaurants(0);
  }

  render() {
    const {
      restaurants, columns, isLoading, err, _delete, totalRows, _loadrestaurants,
    } = this.props;
    if (err) {
      notifyError('Error on fetching datas');
    }

    // eslint-disable-next-line react/prop-types
    const colsValues = columns.length === 0 ? [] : columns[0].values;
    return (
      <RestaurantTable
        dataSource={restaurants.map((res) => res.values)}
        columns={colsValues}
        loading={isLoading}
        model="restaurants"
        deleteFunc={_delete}
        totalRows={totalRows}
        loadTargetPage={_loadrestaurants}
        addBtn
        DetailsComponent={RestaurantDetails}
      />
    );
  }
}

Restaurants.defaultProps = {
  err: null,
};

Restaurants.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  err: PropTypes.shape({}),
  restaurants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalRows: PropTypes.number.isRequired,

  _loadrestaurants: PropTypes.func.isRequired,
  _delete: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  _loadrestaurants: (offset) => dispatch(load('restaurants', offset)),
  _delete: (id) => dispatch(deleteData('restaurants', id)),
});

const mapStateToProps = (state) => ({
  isLoading: state.Restaurants.loading,
  err: state.Restaurants.error,
  restaurants: state.Restaurants.datas,
  columns: state.Restaurants.columns,
  totalRows: state.Restaurants.totalRows,
});

export default connect(mapStateToProps, mapDispatchToProps)(Restaurants);
