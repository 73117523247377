import React from 'react';
import DatePicker from 'antd/es/date-picker';
import moment from 'moment';
import PropTypes from 'prop-types';

import './styles.css';

const { RangePicker } = DatePicker;
const dateFormats = 'YYYY-MM-DD';

const EditableDateField = ({ initialValue, _update }) => {
  const type = typeof initialValue.value === 'object' ? 'range' : 'simple';
  const defaultStart = (initialValue.value || { start: null });
  const defaultEnd = (initialValue.value || { end: null });

  return type === 'range' ? (
    <RangePicker
      className="no-borders"
      defaultValue={[
        !defaultStart.start ? null : moment(initialValue.value.start),
        !defaultEnd.end ? null : moment(initialValue.value.end),
      ]}
      onChange={([start, end]) => {
        if (start !== defaultStart.start || end !== defaultEnd.end) {
          _update(
            initialValue.idInDb,
            initialValue.id,
            {
              start: start ? start.format(dateFormats) : '',
              end: end ? end.format(dateFormats) : '',
            },
          );
        }
      }}
    />
  ) : (
    <DatePicker
      className="no-borders"
      defaultValue={!initialValue.value ? null : moment(initialValue.value)}
      onChange={(momentObj) => {
        if (initialValue.value !== momentObj.format(dateFormats)) {
          _update(
            initialValue.idInDb,
            initialValue.id,
            momentObj ? momentObj.format(dateFormats) : '',
          );
        }
      }}
    />
  );
};

EditableDateField.defaultProps = {
  initialValue: null,
};

EditableDateField.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  _update: PropTypes.func.isRequired,
};


export default EditableDateField;
