/* eslint-disable import/prefer-default-export */
import localStorage from 'local-storage';
import Axios from 'axios';
import history from '../helpers/history';
import client, { apiClient } from '../helpers/HttpClient';
import { USER_OBJECT } from '../constants/localStorage';


export const _getDatasPromiseOnly = ({ model, start }) => client.get(`api/${model}?_start=${start}&_limit=10`);
export const _getDatasCount = (model) => client.get(`api/${model}/count`);
export const _getColumnsPromiseOnly = (type) => client.get(`api/order-columns?type=${type}`);

export const _getDatasWithFilterPromiseOnly = ({ model, start }, { dataIndex, search }) => client.get(`api/${model}/queries?values.${dataIndex}=${search}&_start=${start}&_limit=10`);
export const _getDatasCountWithFilter = ({ model }, { dataIndex, search }) => client.get(`api/${model}/queries/count?values.${dataIndex}=${search}`);

export const _getDatas = (model, cb) => _getDatasPromiseOnly(model)
  .then(({ data }) => {
    if (cb && typeof cb === 'function') { cb(null, data); }
  })
  .catch(cb);

export const _getColumns = (type, cb) => _getColumnsPromiseOnly(type)
  .then(({ data }) => {
    if (cb && typeof cb === 'function') { cb(null, data); }
  })
  .catch(cb);

export const _getDatasAndColumns = ({ model, start, filter }, cb) => {
  let getDatas = _getDatasPromiseOnly({ model, start });
  let getDatasCount = _getDatasCount(model);
  if (filter) {
    getDatas = _getDatasWithFilterPromiseOnly({ model, start }, filter);
    getDatasCount = _getDatasCountWithFilter({ model, start }, filter);
  }
  return Axios.all([
    getDatas,
    _getColumnsPromiseOnly(model),
    getDatasCount,
  ])
    .then(Axios.spread((datas, columns, totalRows) => {
      cb(null, datas, columns, totalRows);
    }))
    .catch(cb);
};

export const getConfigs = () => ({
  logo: `${process.env.PUBLIC_URL}/assets/images/e-sakafo.png`,
  business: {
    name: 'e-sakafo',
  },
});

export const logout = () => {
  localStorage.clear();
  // update http client
  client.setDefaultHeader('Authorization', '');
  history.push('/');
  window.location.reload();
};

export const login = (identifier, password, cb) => client.post('auth/local', {
  identifier, password,
})
  .then(({ data }) => {
    // store credentials in localstorage
    localStorage.set(USER_OBJECT, {
      ...data,
      connected: true,
    });
    // update http client
    client.setDefaultHeader('Authorization', `Bearer ${data.jwt}`);
    // Callback now that all is done
    cb(null, data);
    // change link
    history.push('/dashboard/main');
    window.location.reload();
  })
  .catch((error) => {
    cb(error, null);
  });

export const update = (model, id, datas, cb) => client.put(`api/${model}/${id}`, datas)
  .then(({ data }) => {
    cb(null, data);
  })
  .catch(cb);

export const apiDelete = (model, id, cb) => client.delete(`api/${model}/${id}`)
  .then(({ data }) => {
    cb(null, data);
  })
  .catch(cb);

export const add = (model, datas, cb) => client.post(`api/${model}`, datas)
  .then(({ data }) => {
    cb(null, data);
  })
  .catch(cb);

export const _getOrdersDatasAndColumns = ({
  model, offset, filters, partner, startDate, endDate,
}, cb) => {
  // const getDatas = () => client.get(`api/${model}?_start=${start}&_limit=10&partner=${partner}`);
  const getDatas = () => client.post(`api/${model}/queryFind`, {
    offset, limit: 10, partner, filters, startDate, endDate,
  });
  // const getDatasCount = _getDatasCountWithFilter({ model, offset }, { dataIndex: 'partner', search: partner });
  const getDriverList = apiClient.get('v1/api/driverList');

  return Axios.all([
    getDatas(),
    _getColumnsPromiseOnly(model),
    // getDatasCount,
    getDriverList,
  ])
    .then(Axios.spread((datas, columns, { data: { driverList } }) => {
      cb(null, datas, columns, driverList);
    }))
    .catch(cb);
};

export const _getAccountingDatasAndColumns = ({
  model, offset, filters, partner, startDate, endDate,
}, cb) => {
  // const getDatas = () => client.get(`api/${model}?_start=${start}&_limit=10&partner=${partner}`);
  const getDatas = () => client.post('api/orders/accounting', {
    offset, limit: 1000, partner, filters, startDate, endDate,
  });
  // const getDatasCount = _getDatasCountWithFilter({ model, offset }, { dataIndex: 'partner', search: partner });
  const getDriverList = apiClient.get('v1/api/driverList');

  return Axios.all([
    getDatas(),
    _getColumnsPromiseOnly('accounting'),
    // getDatasCount,
    getDriverList,
  ])
    .then(Axios.spread((datas, columns, { data: { driverList } }) => {
      cb(null, datas, columns, driverList);
    }))
    .catch(cb);
};

export const _uploadFile = (datas, progressFunc) => client.post('upload', datas, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  onUploadProgress: progressFunc,
});

export const processSyncFromCSV = (datas, cb) => client.post('api/orders/sync', datas)
  .then(({ data }) => {
    cb(null, data);
  })
  .catch(cb);
