/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import Table from 'antd/es/table';
import PropTypes from 'prop-types';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import DatePicker from 'antd/es/date-picker';
import Statistic from 'antd/es/statistic';
import Card from 'antd/es/card';
import Select from 'antd/es/select';
import Modal from 'antd/es/modal';
import Divider from 'antd/es/divider';
import Steps from 'antd/es/steps';
import Icon from 'antd/es/icon';
import moment from 'moment';
import { keyBy, get } from 'lodash-es';
import OrderDetails from '../Details/Order/OrderDetails';
import OrderPerItemTable from './OrderPerItemTable';

// import FullscreenModal from '../FullscreenModal/FullscreenModal';
import composeOrderColumns, { addActionColumn } from '../../helpers/Table/composeOrderColumns';
import './styles.css';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Step } = Steps;
const { confirm } = Modal;
const dateFormat = 'DD-MM-YYYY';

const step = {
  Driver: 0,
  OrderTaker: 1,
  Partmer: 2,
};

const AccountingTable = ({
  dataSource, columns, loading, deleteFunc, partnerList, asPartner, role,
  loadTargetPage, displayAction, toBePaidByPartner, toBePaidByEsakafo, updateDataToSave,
}) => {
  const withActionColumns = displayAction ? addActionColumn(columns, deleteFunc) : columns;
  const newCols = composeOrderColumns(withActionColumns, (filter) => loadTargetPage(0, filter), 'accounting', partnerList);

  const [partner, setPartner] = useState(asPartner ? `${asPartner}` : null);
  const [periode, setPeriode] = useState({
    startDate: moment().subtract(1, 'weeks'),
    endDate: moment().add(1, 'days'),
  });
  const [contentToShow, setContentToShow] = useState({});
  const [shownModal, setShownModal] = useState(false);
  const [rowItemToShow, setRowItemToShow] = useState();

  useEffect(() => {
    loadTargetPage(0, partner, periode);
    setRowItemToShow(undefined);
  }, [partner, periode]);

  const setHoldBy = (holdBy, item) => {
    if (item.holdBy !== holdBy) {
      confirm({
        title: 'This action cannot be undone, are you sure to save it ?',
        onOk() {
          const values = get(keyBy(dataSource, 'id'), item.orderId, false);
          if (values) {
            values.items[item.uid].holdBy = holdBy;
            values.items[item.uid].activities = { [holdBy]: moment() };
            // eslint-disable-next-line no-param-reassign
            item.holdBy = holdBy;
            setContentToShow(item);
            updateDataToSave(item.orderId, values);
          }
        },
        okText: 'Yes',
        cancelText: 'No',
      });
    }
  };

  const expandedRowRender = (record) => {
    const orderItems = [];

    for (const [key, value] of Object.entries(record.items)) {
      orderItems.push({
        orderId: record.id,
        alreadyPaid: record.subTotalAlreadyPaid,
        uid: key,
        ...value,
      });
    }

    return (<OrderPerItemTable dataSource={loading ? [] : orderItems} loading={loading} setRowItemToShow={setRowItemToShow} />);
  };

  return (
    <>
      <Modal
        visible={shownModal}
        onCancel={() => setShownModal(false)}
        footer={null}
        className="accounting-modal"
        destroyOnClose
      >
        <OrderDetails data={contentToShow} onlyTotal />
      </Modal>
      <Row gutter={16}>
        <Col xs={24}>
          <Col lg={14} xs={24}>
            <Row style={{ marginBottom: 12 }}>
              <Col xs={24}>
                {asPartner ? null
                  : (
                    <>
                      <h4>Partner</h4>
                      <Select style={{ width: 200 }} placeholder="Select a partner" onChange={(v) => setPartner(v)}>
                        {(partnerList || []).map((p) => (
                          <Option key={`$.opt.${p._id}`} value={p._id}>{p.name}</Option>
                        ))}
                      </Select>
                    </>
                  ) }
                <RangePicker
                  style={{ float: 'right' }}
                  defaultValue={[moment().subtract(1, 'weeks'), moment().add(1, 'days')]}
                  format={dateFormat}
                  onChange={(v) => {
                    const startDate = v[0];
                    const endDate = v[1];
                    setPeriode({ startDate, endDate });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Table
                columns={loading ? [] : newCols}
                size="small"
                dataSource={loading ? [] : dataSource}
                className="order-table"
                scroll={{ y: 570 }}
                rowKey={(dt) => dt._id}
                onRow={(record) => ({
                  onClick: () => {
                    setContentToShow(record);
                    setShownModal(true);
                  },
                })}
                expandedRowRender={expandedRowRender}
                pagination={false}
                loading={loading}
              />
            </Row>
          </Col>
          <Col lg={10} xs={24}>
            <Col xs={24} style={{ marginBottom: 12 }}>
              <Col lg={12} xs={24}>
                <Card>
                  <Statistic title={<h4>Total to be paid by partner to e-sakafo</h4>} groupSeparator=" " suffix="Ar" value={toBePaidByPartner} />
                </Card>
              </Col>
              <Col lg={12} xs={24}>
                <Card>
                  <Statistic title={<h4>Total to be paid by e-sakafo to partner</h4>} groupSeparator=" " suffix="Ar" value={toBePaidByEsakafo} />
                </Card>
              </Col>
            </Col>
          </Col>
          <Col xs={24}>
            {rowItemToShow
              ? (
                <>
                  <br />
                  <Divider orientation="left">{`${rowItemToShow?.itemStatus === 'Refused' ? `Item (${rowItemToShow?.referenceId})` : 'Amount'} transferred to :`}</Divider>
                  <Steps current={step[rowItemToShow?.holdBy]} className="custom-step">
                    <Step
              // onClick={() => (editable ? setStat('Driver') : null)}
                      title="Driver"
                      icon={rowItemToShow?.holdBy === 'Driver' ? <Icon type="check-circle" theme="twoTone" twoToneColor="#91d5ff" /> : null}
                    />
                    <Step
                      onClick={() => (role === 'authenticated' ? setHoldBy('OrderTaker', rowItemToShow) : null)}
                      title="Order Taker"
                      description={rowItemToShow?.activities?.OrderTaker ? moment(rowItemToShow.activities?.OrderTaker).format('DD-MM-YYYY HH:mm') : null}
                      icon={rowItemToShow?.holdBy === 'OrderTaker' ? <Icon type="check-circle" theme="twoTone" twoToneColor="#d3adf7" /> : null}
                    />
                    <Step
                      onClick={() => (role === 'partner' && rowItemToShow?.holdBy === 'OrderTaker' ? setHoldBy('Partner', rowItemToShow) : null)}
                      title="Partner"
                      description={rowItemToShow?.activities?.Partner ? moment(rowItemToShow.activities?.Partner).format('DD-MM-YYYY HH:mm') : null}
                      icon={rowItemToShow?.holdBy === 'Partner' ? <Icon type="check-circle" theme="twoTone" twoToneColor="#13c2c2" /> : null}
                    />
                  </Steps>
                </>
              ) : null }
          </Col>
        </Col>
      </Row>
      {/* <br />
      <Divider orientation="left">Track Per Item</Divider>
      <Row gutter={16}>
        <Col xs={24}>
          <Col xs={14}>
            <OrderPerItemTable dataSource={loading ? [] : orderPerItems} loading={loading} setRowItemToShow={setRowItemToShow} />
          </Col>
          <Col xs={10}>

          </Col>
        </Col>
      </Row> */}
    </>
  );
};

AccountingTable.defaultProps = {
  dataSource: [],
  columns: [],
  loading: true,
  displayAction: true,
};

AccountingTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  deleteFunc: PropTypes.func.isRequired,
  loadTargetPage: PropTypes.func.isRequired,
  // DetailsComponent: PropTypes.elementType.isRequired,
  displayAction: PropTypes.bool,
};

export default AccountingTable;
