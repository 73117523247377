import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { update, store } from '../../redux/ActionsAndReducers/BaseFunction';

import BaseDetails from './BaseDetails';

const CustomerDetails = ({
  data, columns, _updateCustomer, _storeDataToSave, forCreate,
}) => (
  <BaseDetails
    data={data}
    columns={columns}
    update={_updateCustomer}
    store={_storeDataToSave}
    forCreate={forCreate}
    keyId="customersId"
    model="customers"
  />
);

CustomerDetails.defaultProps = {
  columns: [],
  forCreate: false,
};

CustomerDetails.propTypes = {
  data: PropTypes.shape({}).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  _updateCustomer: PropTypes.func.isRequired,
  _storeDataToSave: PropTypes.func.isRequired,
  forCreate: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  _updateCustomer: (id, key, value) => dispatch(update('customers', id, key, value)),
  _storeDataToSave: (id, key, value) => dispatch(store('customers', id, key, value)),
});


export default connect(null, mapDispatchToProps)(CustomerDetails);
