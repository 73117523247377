import Orders from './Orders';
import Restaurants from './Restaurants';
import Menus from './Menus';
import Specialties from './Specialties';
import Customers from './Customers';
import Partners from './Partners';

export default {
  Orders,
  Restaurants,
  Menus,
  Specialties,
  Customers,
  Partners,
};
