import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import { load, deleteData } from '../../redux/ActionsAndReducers/BaseFunction';
import CustomerTable from '../../components/Table/CustomerTable';
import { notifyError } from '../../helpers/Notify';
import CustomerDetails from '../../components/Details/CustomerDetails';

class Customer extends React.Component {
  componentDidMount() {
    const { _loadcustomers } = this.props;
    _loadcustomers(0);
  }

  render() {
    const {
      customers, columns, isLoading, err, _delete, totalRows, _loadcustomers,
    } = this.props;
    if (err) {
      notifyError('Error on fetching datas');
    }

    // eslint-disable-next-line react/prop-types
    const colsValues = columns.length === 0 ? [] : columns[0].values;
    return (
      <CustomerTable
        dataSource={customers.map((res) => res.values)}
        columns={colsValues}
        loading={isLoading}
        model="customers"
        deleteFunc={_delete}
        totalRows={totalRows}
        loadTargetPage={_loadcustomers}
        addBtn
        DetailsComponent={CustomerDetails}
      />
    );
  }
}

Customer.defaultProps = {
  err: null,
};

Customer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  err: PropTypes.shape({}),
  customers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalRows: PropTypes.number.isRequired,

  _loadcustomers: PropTypes.func.isRequired,
  _delete: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  _loadcustomers: (offset) => dispatch(load('customers', offset)),
  _delete: (id) => dispatch(deleteData('customers', id)),
});

const mapStateToProps = (state) => ({
  isLoading: state.Customers.loading,
  err: state.Customers.error,
  customers: state.Customers.datas,
  columns: state.Customers.columns,
  totalRows: state.Customers.totalRows,
});

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
