import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import localStorage from 'local-storage';
import PropTypes from 'prop-types';

import client from '../helpers/HttpClient';
import { USER_OBJECT } from '../constants/localStorage';

const RestrictedComponent = ({ Component }) => {
  // get object connected status
  const userObj = localStorage.get(USER_OBJECT);
  if (!userObj || !userObj.connected) {
    // redirect to root
    return <Redirect to="/" />;
  }
  // set client header
  if (userObj.jwt) {
    client.setDefaultHeader('Authorization', `Bearer ${userObj.jwt}`);
  }
  // render the component
  return <Component />;
};

RestrictedComponent.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.object]).isRequired,
};

export default withRouter(RestrictedComponent);
