import update from 'immutability-helper';
import { findKey } from 'lodash-es';

export default class OrderModel {
  constructor() {
    this.datas = [];
    this.tempData = {};
    this.columns = [];
    this.loading = false;
    this.error = null;
    this.fieldsLoading = {};
    this.onSaving = false;
    this.totalRows = 0;
    this.shownModal = false;
    this.partnerList = [];
    this.orderTakers = [];
    this.driverList = [];
  }

  setColumns(cols) {
    return update(this, {
      columns: { $set: cols },
      loading: { $set: false },
    });
  }

  setDatas(datas) {
    return update(this, {
      datas: { $set: datas },
      loading: { $set: false },
    });
  }

  setPartnerList(datas) {
    return update(this, {
      partnerList: { $set: datas },
      loading: { $set: false },
    });
  }

  setOrderTakerList(datas) {
    return update(this, {
      orderTakers: { $set: datas },
      loading: { $set: false },
    });
  }

  setDriverList(datas) {
    return update(this, {
      driverList: { $set: datas },
      loading: { $set: false },
    });
  }

  setTotalRows(totalRows) {
    return update(this, {
      totalRows: { $set: totalRows },
    });
  }

  deleteOneData(dataId) {
    const i = findKey(this.datas, (data) => data._id === dataId);
    if (!i) {
      return update(this, {
        loading: { $set: false },
      });
    }

    return update(this, {
      datas: { $splice: [[i, 1]] },
      loading: { $set: false },
    });
  }

  setOneData(dataId, key, value) {
    const i = findKey(this.datas, (data) => data._id === dataId);
    if (!i) {
      return this;
    }

    const newValues = {
      ...this.datas[i].values,
      [key]: value,
    };

    return update(this, {
      datas: {
        [i]: {
          values: { $set: newValues },
        },
      },
    });
  }

  storeUpdatedRow({ id, values }) {
    const i = findKey(this.datas, (data) => data._id === id);
    if (!i) {
      return this;
    }

    return update(this, {
      datas: {
        [i]: {
          values: { $set: values },
        },
      },
      shownModal: { $set: false },
      onSaving: { $set: false },
    });
  }

  storeNewRow(row) {
    return update(this, {
      datas: { $push: [row] },
      onSaving: { $set: false },
      tempData: { $set: {} },
      shownModal: { $set: false },
    });
  }

  addDataToSave({ key, value }) {
    return update(this, {
      tempData: { [key]: { $set: value } },
    });
  }

  setColumnsAndDatas({
    columns, datas, partnerList, totalRows, orderTakers, driverList,
  }) {
    const newSelf = this.setColumns(columns);
    const _newSelf = newSelf.setTotalRows(totalRows);
    const __newSelf = _newSelf.setPartnerList(partnerList);
    const ___newSelf = __newSelf.setOrderTakerList(orderTakers);
    const ____newSelf = ___newSelf.setDriverList(driverList);
    return ____newSelf.setDatas(datas);
  }

  toggleLoading(v) {
    return update(this, { loading: { $set: v } });
  }

  setError(err) {
    return update(this, {
      loading: { $set: false },
      onSaving: { $set: false },
      error: { $set: err },
      tempData: { $set: {} },
    });
  }

  toggleOnSaving() {
    return update(this, {
      onSaving: { $set: !this.onSaving },
    });
  }

  toggleModal(value) {
    return update(this, {
      shownModal: { $set: value },
    });
  }
}
