/* eslint-disable react/prop-types */
import { connect } from 'react-redux';
import React from 'react';
import message from 'antd/es/message';
import { getModelState } from '../redux/ActionsAndReducers/BaseFunction';

class FieldUpdatesFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.hideBox = null;
  }

  shouldComponentUpdate(newProps) {
    const { fieldUpdates, updateKey } = newProps;
    // eslint-disable-next-line react/destructuring-assignment
    return fieldUpdates[updateKey] !== this.props.fieldUpdates[updateKey];
  }

  componentDidUpdate() {
    const { updateKey, fieldUpdates } = this.props;
    if (fieldUpdates[updateKey]) {
      this.hideBox = message.loading(`Updating ${updateKey} field...`, 0);
    }

    if (!fieldUpdates[updateKey] && this.hideBox) {
      this.hideBox();
      this.hideBox = null;
    }
  }

  componentWillUnmount() {
    if (this.hideBox) {
      message.destroy();
      this.hideBox = null;
    }
  }


  render() {
    const { children } = this.props;

    return (children);
  }
}

const mapStateToProps = (state, props) => {
  const { fieldsLoading } = getModelState(props.model, state);
  return {
    fieldUpdates: fieldsLoading,
  };
};

export default connect(mapStateToProps, null)(FieldUpdatesFeedback);
