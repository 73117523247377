
import React from 'react';
import Table from 'antd/es/table';
import Tag from 'antd/es/tag';

const colors = {
  Received: 'blue',
  Refused: 'red',
};

const columns = [
  /* {
    title: 'Order Id',
    dataIndex: 'orderId',
    key: 'orderId',
    width: 80,
  }, */
  {
    title: 'Reference Id',
    dataIndex: 'referenceId',
    key: 'referenceId',
    width: 100,
  },
  {
    title: 'Item Name',
    dataIndex: 'itemName',
    key: 'itemName',
    width: 250,
  },
  {
    title: 'Status',
    dataIndex: 'itemStatus',
    key: 'itemStatus',
    width: 150,
    render: (text) => (<Tag color={colors[text]}>{text}</Tag>),
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    width: 100,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    width: 100,
  },
];

const OrderPerItemTable = ({ dataSource, loading, setRowItemToShow }) => (
  <Table
    scroll={{ x: true }}
    loading={loading}
    size="small"
    dataSource={dataSource}
    columns={columns}
    className="order-table"
    pagination={false}
    rowKey={(dt) => dt.uid}
    onRow={(record) => ({
      onClick: () => setRowItemToShow({ ...record, holdBy: record.holdBy || 'Driver' }),
    })}
  />
);

export default OrderPerItemTable;
