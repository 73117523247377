import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import configureStore from '../redux/configureStore';
import Login from './user/Login';
import NotFound404 from './errors/404';
import DashboardLayout from './dashboard/routeDashboard';
// import Main from './dashboard/main';

const routeAllScreens = () => (
  <Provider store={configureStore()}>
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/dashboard" component={DashboardLayout} />

      {/* Default route */}
      <Route component={NotFound404} />
    </Switch>
  </Provider>
);

export default routeAllScreens;
