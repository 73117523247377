import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { update, store } from '../../redux/ActionsAndReducers/BaseFunction';

import BaseDetails from './BaseDetails';

const RestaurantDetails = ({
  data, columns, _updateRestaurant, _storeDataToSave, forCreate,
}) => (
  <BaseDetails
    data={data}
    columns={columns}
    update={_updateRestaurant}
    store={_storeDataToSave}
    forCreate={forCreate}
    keyId="restaurantsId"
    model="restaurants"
  />
);

RestaurantDetails.defaultProps = {
  columns: [],
  forCreate: false,
};

RestaurantDetails.propTypes = {
  data: PropTypes.shape({}).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  _updateRestaurant: PropTypes.func.isRequired,
  _storeDataToSave: PropTypes.func.isRequired,
  forCreate: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  _updateRestaurant: (id, key, value) => dispatch(update('restaurants', id, key, value)),
  _storeDataToSave: (id, key, value) => dispatch(store('restaurants', id, key, value)),
});


export default connect(null, mapDispatchToProps)(RestaurantDetails);
