import React from 'react';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import PropTypes from 'prop-types';
import Row from 'antd/es/row';
import Col from 'antd/es/col';

import FullscreenModal from '../FullscreenModal/FullscreenModal';
import composeOrderColumns, { addActionColumn } from '../../helpers/Table/composeOrderColumns';
import './styles.css';
import Uploader from '../Uploader';

const windowDIffCoeff = 325;

class OrderTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTextToggle: false,
      contentToShow: {},
      height: window.innerHeight - (windowDIffCoeff + 30),
      forCreate: false,
    };

    this.handleChanges = this.handleChanges.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleChanges() {
    const { searchTextToggle } = this.state;
    this.setState({ searchTextToggle: !searchTextToggle });
  }

  handleWindowResize() {
    this.setState({ height: window.innerHeight - windowDIffCoeff });
  }

  renderModal() {
    const {
      DetailsComponent, shownModal, modalToggle, asPartner,
    } = this.props;
    const { forCreate, contentToShow } = this.state;
    return (
      <FullscreenModal
        visible={shownModal}
        cancel={() => {
          modalToggle(false);
        }}
        renderContent={() => (
          <DetailsComponent
            partner={asPartner}
            forCreate={forCreate}
            data={contentToShow}
            cancel={() => {
              modalToggle(false);
            }}
          />
        )}
      />
    );
  }

  render() {
    const {
      dataSource, columns, loading, addBtn, model, deleteFunc, totalRows,
      loadTargetPage, displayAction, modalToggle, partnerList, asPartner,
    } = this.props;
    const { height } = this.state;
    const withActionColumns = displayAction ? addActionColumn(columns, deleteFunc) : columns;
    const newCols = composeOrderColumns(withActionColumns, (filter) => loadTargetPage(0, filter), 'orders', partnerList, asPartner);

    return (
      <>
        {this.renderModal()}
        <h2>{model.charAt(0).toUpperCase() + model.slice(1)}</h2>
        {addBtn ? (
          <Row style={{ textAlign: 'right', marginBottom: '1%' }} gutter={16}>
            <Col xs={24}>
              <Col lg={23} xs={20}>
                <Uploader />
              </Col>
              <Col lg={1} xs={4}>
                <Button
                  type="primary"
              // className="add-button"
                  onClick={() => {
                    this.setState({
                      contentToShow: {},
                      forCreate: true,
                    }, () => modalToggle(true));
                  }}
                >
Add
                </Button>
              </Col>
            </Col>
          </Row>
        ) : null}

        <Table
          columns={loading ? [] : newCols}
          // bordered
          dataSource={loading ? [] : dataSource}
          className="order-table"
          // scroll={{ x: 1000, y: height }}
          scroll={{ x: true }}
          rowKey={(dt) => dt._id}
          onRow={(record) => ({
            onClick: () => {
              this.setState({ contentToShow: record, forCreate: false }, () => modalToggle(true));
            },
          })}
          // onChange={this.handleTableChanges}
          pagination={{
            total: totalRows,
            /* onChange: (page, pageSize) => {
              if (pageSize) {
                const offset = page && page === 1 ? 0 : (page - 1) * pageSize;
                loadTargetPage(offset);
              }
            }, */
          }}
          rowClassName={(record) => (record.new ? 'new-row' : '')}
          loading={loading}
          onChange={(pagination, filters, sorter) => {
            const { current, pageSize } = pagination;
            let offset = 0;
            if (pageSize) {
              offset = current && current === 1 ? 0 : (current - 1) * pageSize;
            }
            const _filters = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const [key, value] of Object.entries(filters)) {
              if (value[0]) { _filters.push({ dataIndex: `values.${key}`, search: value[0] }); }
            }
            loadTargetPage(offset, _filters);
          }}
        />
      </>
    );
  }
}

OrderTable.defaultProps = {
  dataSource: [],
  columns: [],
  loading: true,
  displayAction: true,
};

OrderTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  model: PropTypes.string.isRequired,
  addBtn: PropTypes.bool.isRequired,
  deleteFunc: PropTypes.func.isRequired,
  totalRows: PropTypes.number.isRequired,
  loadTargetPage: PropTypes.func.isRequired,
  DetailsComponent: PropTypes.elementType.isRequired,
  displayAction: PropTypes.bool,
};

export default OrderTable;
