import { find, cloneDeep } from 'lodash-es';
import {
  add, _getDatasAndColumns, apiDelete, update as apiUpdate,
} from '../../api/Methods';

export const getModelState = (model, getState) => {
  const state = typeof getState === 'function' ? getState() : getState;
  switch (model) {
    case 'restaurants': return state.Restaurants;
    case 'orders': return state.Orders;
    case 'menus': return state.Menus;
    case 'specialties': return state.Specialties;
    case 'customers': return state.Customers;
    default:
      return {};
  }
};

const computeDataIds = (datas, keyId) => datas.map((row) => {
  const _row = cloneDeep(row);
  _row.values[keyId] = `${_row.identifier || 0} - ${_row.values.name || ''}`;

  return _row;
});

export const actionManager = (state, action, model) => {
  switch (action.type) {
    // Data loading related
    case `${model}/loading`:
      return state.toggleLoading();
    case `${model}/loaded_with_success`:
      return state.setColumnsAndDatas(action.columns, action.datas, action.totalRows);
    case `${model}/not_loaded_has_error`:
      return state.setError(action.error);

      // Data update related
    case `${model}/request_an_field_update`:
      return state.toggleFieldLoading(action.key);
    case `${model}/field_update_success`:
      return state.toggleFieldLoading(action.key).setOneData(action.id, action.key, action.value);
    case `${model}/field_update_failed`:
      return state.toggleFieldLoading(action.key).setError(action.error);

    // Add
    case `${model}/request_save`:
      return state.toggleOnSaving();
    case `${model}/store`:
      return state.addDataToSave(action.data);
    case `${model}/store_new_row`:
      return state.storeNewRow(action.data);

    // Delete
    case `${model}/delete_row`:
      return state.deleteOneData(action.id);

    default:
      return state;
  }
};


export const load = (model, offset, filter = null, noValues = false) => (dispatch) => {
  dispatch({ type: `${model}/loading` });

  _getDatasAndColumns({ model, start: offset, filter }, (error, datas, columns, totalRows) => {
    if (error) {
      return dispatch({ type: `${model}/not_loaded_has_error`, error });
    }

    const dataWithIds = !noValues ? computeDataIds(datas.data || [], `${model}Id`) : datas.data;

    return dispatch({
      type: `${model}/loaded_with_success`,
      datas: dataWithIds.map((data) => {
        const newData = {
          ...data,
        };
        if (!noValues) { newData.values._id = data.id; }
        return newData;
      }),
      columns: columns.data,
      totalRows: totalRows.data,
    });
  });
};

// eslint-disable-next-line no-unused-vars
export const store = (model, id = null, key, value) => (dispatchEvent) => {
  dispatchEvent({ type: `${model}/store`, data: { key, value } });
};

export const save = (model) => (dispatchEvent, getState) => {
  dispatchEvent({ type: `${model}/request_save`, data: null });

  const { tempData } = getModelState(model, getState);
  return add(model, { values: tempData }, (err, data) => {
    if (err) {
      return dispatchEvent({ type: `${model}/not_loaded_has_error`, err });
    }
    const _row = cloneDeep(data);
    _row.values[`${model}Id`] = `${_row.identifier || 0} - ${_row.values.name || ''}`;
    _row.values._id = _row._id;
    return dispatchEvent({ type: `${model}/store_new_row`, data: _row });
  });
};

export const update = (model, id, key, value) => (dispatch, getState) => {
  dispatch({ type: `${model}/request_an_field_update`, key });

  // send the correct new data
  const currentDatas = getModelState(model, getState);
  const newDatas = currentDatas.setOneData(id, key, value);
  const correctData = find(newDatas.datas, (row) => row._id === id);

  if (!correctData) {
    return dispatch({ type: `${model}/field_update_failed`, key, error: new Error('Restaurant not found in redux store') });
  }

  return apiUpdate(model, id, correctData, (err) => {
    if (err) {
      return dispatch({ type: `${model}/field_update_failed`, key, error: err });
    }

    return dispatch({
      type: `${model}/field_update_success`, key, value, id,
    });
  });
};

export const deleteData = (model, id) => (dispatchEvent) => {
  dispatchEvent({ type: `${model}/loading` });

  apiDelete(model, id, (err) => {
    if (err) {
      console.log(err);
    }
    return dispatchEvent({
      type: `${model}/delete_row`, id,
    });
  });
};
