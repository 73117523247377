import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import localStorage from 'local-storage';
import get from 'lodash-es/get';
import omit from 'lodash-es/omit';
import keyBy from 'lodash-es/keyBy';

import { load, modalToggle } from '../../redux/ActionsAndReducers/Orders';
import { deleteData } from '../../redux/ActionsAndReducers/BaseFunction';
import OrderTable from '../../components/Table/OrderTable';
import { notifyError } from '../../helpers/Notify';
import OrderDetails from '../../components/Details/Order/OrderDetails';

const userObj = localStorage.get('user_object');

class Main extends React.Component {
  componentDidMount() {
    const { _loadOrders } = this.props;
    _loadOrders(0);
  }

  render() {
    const {
      orders, columns, isLoading, err, _delete, totalRows, _loadOrders, shownModal, _modalToggle, partnerList,
    } = this.props;
    if (err) {
      notifyError('Error on fetching datas');
    }

    // const filteredOrders = orders.map((order) => order.values).filter((order) => order.status === 'Lead');
    // eslint-disable-next-line react/prop-types
    const asPartner = get(userObj, 'user.role.type', '') === 'partner' ? get(userObj, 'user.partner._id', null) : null;
    let colsValues = columns.length === 0 ? [] : columns[0].values;
    if (asPartner && colsValues.length !== 0) {
      colsValues = Object.values(omit(keyBy(colsValues, 'key'), ['partner', 'orderTaker']));
    }
    return (
      <OrderTable
        dataSource={orders.map((res) => res.values)}
        columns={colsValues}
        loading={isLoading}
        model="orders"
        deleteFunc={_delete}
        totalRows={totalRows}
        loadTargetPage={_loadOrders}
        addBtn
        shownModal={shownModal}
        modalToggle={_modalToggle}
        displayAction
        DetailsComponent={OrderDetails}
        asPartner={asPartner}
        partnerList={partnerList}
      />
    );
  }
}

Main.defaultProps = {
  err: null,
};

Main.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  shownModal: PropTypes.bool.isRequired,
  err: PropTypes.shape({}),
  orders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalRows: PropTypes.number.isRequired,
  _delete: PropTypes.func.isRequired,
  _loadOrders: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  _loadOrders: (offset, filters) => dispatch(load('orders', offset, filters)),
  _delete: (id) => dispatch(deleteData('orders', id)),
  _modalToggle: (value) => dispatch(modalToggle(value)),
});

const mapStateToProps = ({ Orders }) => ({
  isLoading: Orders.loading,
  err: Orders.error,
  orders: Orders.datas,
  columns: Orders.columns,
  totalRows: Orders.totalRows,
  shownModal: Orders.shownModal,
  partnerList: Orders.partnerList,
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
