import update from 'immutability-helper';
import { get, findKey } from 'lodash-es';

export default class BaseModel {
  constructor() {
    this.datas = [];
    this.tempData = {};
    this.columns = [];
    this.loading = false;
    this.error = null;
    this.fieldsLoading = {};
    this.onSaving = false;
    this.totalRows = 0;
  }

  setColumns(cols) {
    return update(this, {
      columns: { $set: cols },
      loading: { $set: false },
    });
  }

  setDatas(datas) {
    return update(this, {
      datas: { $set: datas },
      loading: { $set: false },
    });
  }

  setTotalRows(totalRows) {
    return update(this, {
      totalRows: { $set: totalRows },
    });
  }

  deleteOneData(dataId) {
    const i = findKey(this.datas, (data) => data._id === dataId);
    if (!i) {
      return update(this, {
        loading: { $set: false },
      });
    }

    return update(this, {
      datas: { $splice: [[i, 1]] },
      loading: { $set: false },
    });
  }

  setOneData(dataId, key, value) {
    const i = findKey(this.datas, (data) => data._id === dataId);
    if (!i) {
      return this;
    }

    const newValues = {
      ...this.datas[i].values,
      [key]: value,
    };

    return update(this, {
      datas: {
        [i]: {
          values: { $set: newValues },
        },
      },
    });
  }

  storeNewRow(row) {
    return update(this, {
      datas: { $push: [row] },
      onSaving: { $set: false },
      tempData: { $set: {} },
    });
  }

  addDataToSave({ key, value }) {
    return update(this, {
      tempData: { [key]: { $set: value } },
    });
  }

  setColumnsAndDatas(cols, datas, totalRows) {
    const newSelf = this.setColumns(cols);
    const _newSelf = newSelf.setTotalRows(totalRows);
    return _newSelf.setDatas(datas);
  }

  toggleLoading() {
    return update(this, { loading: { $set: !this.loading } });
  }

  setError(err) {
    return update(this, {
      loading: { $set: false },
      onSaving: { $set: false },
      error: { $set: err },
      tempData: { $set: {} },
    });
  }

  toggleOnSaving() {
    return update(this, {
      onSaving: { $set: !this.onSaving },
    });
  }

  toggleFieldLoading(key) {
    return update(this, {
      fieldsLoading: {
        [key]: {
          $set: !get(this, `fieldsLoading.${key}`, false),
        },
      },
    });
  }
}
