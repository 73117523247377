/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Upload, Button, Icon,
} from 'antd';
import localStorage from 'local-storage';
import { notifyError, notifySuccess } from '../../helpers/Notify';

import './style.css';

import { _uploadFile, processSyncFromCSV } from '../../api/Methods';

const userObj = localStorage.get('user_object');

// eslint-disable-next-line class-methods-use-this
const upload = (file, onSuccess, onError, onProgress) => {
  const formData = new FormData();
  formData.append('files', file);

  return _uploadFile(formData, (progressEvt) => {
    // handle progress
    const progress = Math.round((progressEvt.loaded * 100) / progressEvt.total);
    onProgress({ percent: progress });
  })
    .then((response) => {
      const { url } = response.data[0];
      processSyncFromCSV({ url, user: userObj.user }, (err, data) => {
        if (err) {
          onError(err);
          notifyError('An error occurred while syncing your file');
        }
        onSuccess(response);
        notifySuccess('Sync process done');
        setTimeout(() => window.location.reload(), 500);
      });
    })
    .catch((err) => {
      onError(err);
    });
};

const props = {
  name: 'file',
  accept: '.csv',
  customRequest: ({
    file, onSuccess, onError, onProgress,
  }) => upload(file, onSuccess, onError, onProgress),
  method: 'POST',
};

const Uploader = () => (
  <Upload className="import-csv" {...props}>
    <Button>
      <Icon type="upload" />
      {' '}
        Import from CSV
    </Button>
  </Upload>
);

export default Uploader;
