import React from 'react';

import LoginComponent from '../../components/Login/Login';
import { getConfigs } from '../../api/Methods';

const configs = getConfigs();
const { logo } = configs;

const Login = () => (
  <LoginComponent logo={logo} />
);

export default Login;
