import React from 'react';
import Modal from 'antd/es/modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { save, getModelState } from '../../redux/ActionsAndReducers/BaseFunction';

const CreateModal = ({
  visible, cancel, renderContent, _save, model, _onSaving,
}) => (
  <Modal
    visible={visible}
    okText="Save"
    okButtonProps={{ disabled: _onSaving, loading: _onSaving }}
    cancelButtonProps={{ disabled: _onSaving }}
    // cancelButtonProps={{ disabled: true }}
    onCancel={cancel}
   // footer={null}
    className="fullscreen-modal"
    destroyOnClose
    onOk={() => _save(model)}
  >
    {renderContent()}
  </Modal>
);

CreateModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  renderContent: PropTypes.func.isRequired,
  _save: PropTypes.func.isRequired,
  model: PropTypes.string.isRequired,
  _onSaving: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
  const { onSaving } = getModelState(props.model, state);
  return {
    _onSaving: onSaving,
  };
};

const mapDisptachToProps = (dispatchEvent) => ({
  _save: (model) => dispatchEvent(save(model)),
});
export default connect(mapStateToProps, mapDisptachToProps)(CreateModal);
