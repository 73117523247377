import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { get, findKey } from 'lodash-es';
import { update as upt, store } from '../../redux/ActionsAndReducers/BaseFunction';

import BaseDetails from './BaseDetails';

const resolveColumns = (columns, key, list) => {
  const i = findKey(columns, (col) => col.dataIndex === `${key}.name`);
  const opt = get(columns, [i, 'options']);
  if (opt !== undefined && opt === 'FETCH') {
    const keyValues = (list || []).map((l) => ({ id: get(l, 'values._id', ''), name: get(l, 'values.name', '') }));
    return update(columns, {
      [i]: { dataIndex: { $set: key }, key: { $set: key }, options: { $set: keyValues } },
    });
  }

  return update(columns, {
    [i]: { options: { $set: [] } },
  });
};

const MenuDetails = ({
  data, columns, _update, _storeDataToSave, forCreate, restaurants, specialties,
}) => {
  let cols = resolveColumns(columns, 'restaurant', restaurants);
  cols = resolveColumns(cols, 'specialty', specialties);

  return (
    <BaseDetails
      data={data}
      columns={cols}
      update={_update}
      store={_storeDataToSave}
      forCreate={forCreate}
      keyId="menusId"
      model="menus"
    />
  );
};

MenuDetails.defaultProps = {
  columns: [],
  forCreate: false,
  restaurants: [],
  specialties: [],
};

MenuDetails.propTypes = {
  data: PropTypes.shape({}).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  _update: PropTypes.func.isRequired,
  _storeDataToSave: PropTypes.func.isRequired,
  forCreate: PropTypes.bool,
  restaurants: PropTypes.arrayOf(PropTypes.shape({})),
  specialties: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state) => ({
  restaurants: state.Restaurants.datas,
  specialties: state.Specialties.datas,
});

const mapDispatchToProps = (dispatch) => ({
  _update: (id, key, value) => dispatch(upt('menus', id, key, value)),
  _storeDataToSave: (id, key, value) => dispatch(store('menus', id, key, value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(MenuDetails);
