import React from 'react';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import PropTypes from 'prop-types';

import FullscreenModal from '../FullscreenModal/FullscreenModal';
import composeColumns, { addActionColumn } from '../../helpers/Table/composeColumns';
import CreateModal from '../FullscreenModal/CreateModal';
import './styles.css';

const windowDIffCoeff = 325;

class BaseTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTextToggle: false,
      modalShown: false,
      contentToShow: {},
      height: window.innerHeight - windowDIffCoeff,
      forCreate: false,
    };

    this.handleChanges = this.handleChanges.bind(this);
    this.handleTableChanges = this.handleTableChanges.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleChanges() {
    const { searchTextToggle } = this.state;
    this.setState({ searchTextToggle: !searchTextToggle });
  }

  handleWindowResize() {
    this.setState({ height: window.innerHeight - windowDIffCoeff });
  }

  handleTableChanges(pagination, filters, sorter) {
    console.log(pagination, filters, sorter);
  }

  renderModal() {
    const {
      columns, DetailsComponent, model,
    } = this.props;
    const { forCreate, contentToShow, modalShown } = this.state;
    if (forCreate) {
      return (
        <CreateModal
          visible={modalShown}
          model={model}
          cancel={() => {
            this.setState({ modalShown: false });
          }}
          renderContent={() => (
            <DetailsComponent forCreate={forCreate} data={contentToShow} columns={columns} />
          )}
        />
      );
    }

    return (
      <FullscreenModal
        visible={modalShown}
        cancel={() => {
          this.setState({ modalShown: false });
        }}
        renderContent={() => (
          <DetailsComponent forCreate={forCreate} data={contentToShow} columns={columns} />
        )}
      />
    );
  }

  render() {
    const {
      dataSource, columns, loading, addBtn, model, deleteFunc, totalRows,
      loadTargetPage, displayAction,
    } = this.props;
    const { height } = this.state;
    const withActionColumns = displayAction ? addActionColumn(columns, deleteFunc) : columns;
    const newCols = composeColumns(withActionColumns, (filter) => loadTargetPage(0, filter));

    return (
      <>
        {this.renderModal()}
        <h2>{model.charAt(0).toUpperCase() + model.slice(1)}</h2>
        {addBtn ? (
          <Button
            type="primary"
            className="add-button"
            onClick={() => {
              this.setState({
                modalShown: true,
                contentToShow: {},
                forCreate: true,
              });
            }}
          >
Add

          </Button>
        ) : null}

        <Table
          columns={newCols}
          bordered
          dataSource={dataSource}
          className="order-table"
          scroll={{ x: 1000, y: height }}
          rowKey={(dt) => dt._id}
          onRow={(record) => ({
            onClick: () => {
              this.setState({ modalShown: true, contentToShow: record, forCreate: false });
            },
          })}
          onChange={this.handleTableChanges}
          pagination={{
            total: totalRows,
            onChange: (page, pageSize) => {
              if (pageSize) {
                const offset = page && page === 1 ? 0 : (page - 1) * pageSize;
                loadTargetPage(offset);
              }
            },
          }}
          rowClassName={(record) => (record.new ? 'new-row' : '')}
          loading={loading}
        />
      </>
    );
  }
}

BaseTable.defaultProps = {
  dataSource: [],
  columns: [],
  loading: true,
  displayAction: true,
};

BaseTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  model: PropTypes.string.isRequired,
  addBtn: PropTypes.bool.isRequired,
  deleteFunc: PropTypes.func.isRequired,
  totalRows: PropTypes.number.isRequired,
  loadTargetPage: PropTypes.func.isRequired,
  DetailsComponent: PropTypes.elementType.isRequired,
  displayAction: PropTypes.bool,
};

export default BaseTable;
