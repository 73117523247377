import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'antd/es/layout';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Icon from 'antd/es/icon';
// import SubMenu from 'antd/es/menu/SubMenu';
import localStorage from 'local-storage';

import history from '../../helpers/history';
import { getConfigs, logout } from '../../api/Methods';
import './styles.css';

const { Header, Content, Footer } = Layout;
const configs = getConfigs();
const { logo, business } = configs;
const year = (new Date()).getFullYear();
const userObj = localStorage.get('user_object');

const getCurrentPageMenuIndex = () => {
  const url = window.location.href;
  const menus = ['main', 'accounting', 'partners', 'restaurants', 'menus', 'specialties', 'customers'];
  let res = null;
  menus.forEach((val, index) => {
    if (url.includes(val)) {
      res = index;
    }
  });

  return res.toString();
};

const user = (
  <Menu
    theme="light"
    mode="vertical"
    style={{ lineHeight: '64px', float: 'right' }}
  >
    <Menu.Item onClick={logout}>
      <Icon type="logout" />
      {' '}
          Logout
    </Menu.Item>
  </Menu>
);

const DashboardLayout = ({ children }) => (
  <Layout>
    <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
      <div className="logo">
        <img src="https://www.e-sakafo.mg/assets/admin/img/logo.png" alt="Logo" style={{ height: '85%' }} />
      </div>
      <Menu
        theme="light"
        mode="horizontal"
        defaultSelectedKeys={[getCurrentPageMenuIndex()]}
        style={{ lineHeight: '64px', float: 'left' }}
      >
        <Menu.Item key="0" onClick={() => { history.push('/dashboard/main'); }}>Orders</Menu.Item>
        <Menu.Item key="1" onClick={() => { history.push('/dashboard/accounting'); }}>Accounting</Menu.Item>
        {/* <Menu.Item key="1" onClick={() => { history.push('/dashboard/partners'); }}>Partners</Menu.Item>
        <SubMenu title="Restaurants">
          <Menu.Item key="2" onClick={() => { history.push('/dashboard/restaurants'); }}>List</Menu.Item>
          <Menu.Item key="3" onClick={() => { history.push('/dashboard/menus'); }}>Menus</Menu.Item>
          <Menu.Item key="4" onClick={() => { history.push('/dashboard/specialties'); }}>Specialties</Menu.Item>
        </SubMenu>
<Menu.Item key="5" onClick={() => { history.push('/dashboard/customers'); }}>Customers</Menu.Item> */}
      </Menu>
      <div style={{ float: 'right' }}>
        <Dropdown overlay={user} trigger={['click']} placement="bottomCenter">
          <span style={{ fontSize: '14px', cursor: 'pointer' }}>
            <Icon style={{ fontSize: '16px', marginRight: 5 }} type="user" onClick={(e) => e.preventDefault()} />
            {`${userObj?.user?.username || ''}`}
          </span>
        </Dropdown>
      </div>

    </Header>
    <Content style={{ padding: '40px 50px', marginTop: 64 }}>
      <div style={{
        background: '#fff', padding: 24, minHeight: 380, /* paddingBottom: '5%', */
      }}
      >
        {children}
      </div>
    </Content>
    {/* <Footer style={{
      textAlign: 'center', position: 'fixed', width: '100%', bottom: 0,
    }}
    >
    ©
      {business.name}
      {' '}
      {year}
  </Footer> */}
  </Layout>
);

DashboardLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default DashboardLayout;
