import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import { load, deleteData } from '../../redux/ActionsAndReducers/BaseFunction';
import PartnerTable from '../../components/Table/PartnerTable';
import { notifyError } from '../../helpers/Notify';
import PartnerDetails from '../../components/Details/PartnerDetails';

class Partner extends React.Component {
  componentDidMount() {
    const { _loadpartners } = this.props;
    _loadpartners(0);
  }

  render() {
    const {
      partners, columns, isLoading, err, _delete, totalRows, _loadpartners,
    } = this.props;
    if (err) {
      notifyError('Error on fetching datas');
    }

    // eslint-disable-next-line react/prop-types
    const colsValues = columns.length === 0 ? [] : columns[0].values;
    return (
      <PartnerTable
        dataSource={partners}
        columns={colsValues}
        loading={isLoading}
        model="partners"
        deleteFunc={_delete}
        totalRows={totalRows}
        loadTargetPage={_loadpartners}
        addBtn={false}
        displayAction={false}
        DetailsComponent={PartnerDetails}
      />
    );
  }
}

Partner.defaultProps = {
  err: null,
};

Partner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  err: PropTypes.shape({}),
  partners: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalRows: PropTypes.number.isRequired,

  _loadpartners: PropTypes.func.isRequired,
  _delete: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  _loadpartners: (offset) => dispatch(load('partners', offset, null, true)),
  _delete: (id) => dispatch(deleteData('partners', id)),
});

const mapStateToProps = (state) => ({
  isLoading: state.Partners.loading,
  err: state.Partners.error,
  partners: state.Partners.datas,
  columns: state.Partners.columns,
  totalRows: state.Partners.totalRows,
});

export default connect(mapStateToProps, mapDispatchToProps)(Partner);
