/* eslint-disable max-classes-per-file */
import axios from 'axios';

export class HttpClient {
  constructor() {
    this.client = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL || 'http://localhost:1337/dashboard',
    });
  }

  setDefaultHeader(name, value) {
    if (!name) {
      throw new Error('Name and value are required');
    }

    this.client.defaults.headers.common[name] = value;
  }

  async get(...args) {
    return this.client.get(...args);
  }

  async post(...args) {
    return this.client.post(...args);
  }

  async put(...args) {
    return this.client.put(...args);
  }

  async delete(...args) {
    return this.client.delete(...args);
  }

  async patch(...args) {
    return this.client.patch(...args);
  }
}

const client = new HttpClient();

export default client;

export class ApiHttpClient {
  constructor(baseURL) {
    this.client = axios.create({
      baseURL,
    });
  }

  async post(...args) {
    return this.client.post(...args);
  }

  async get(...args) {
    return this.client.get(...args);
  }
}

const apiClient = new ApiHttpClient(process.env.REACT_APP_API_URL);
const apiSms = new ApiHttpClient(process.env.REACT_APP_SMS_API_URL);

export { apiClient, apiSms };
