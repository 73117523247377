import { cloneDeep, pick, keyBy } from 'lodash-es';
import localStorage from 'local-storage';
import OrderModel from '../DataModels/Order';
import {
  add, _getOrdersDatasAndColumns, _getAccountingDatasAndColumns, update as apiUpdate,
} from '../../api/Methods';

const userObj = localStorage.get('user_object');
// ///////////////
// Reducers
// ///////////////
const orderDM = new OrderModel();
const Orders = (state = orderDM, action) => {
  switch (action.type) {
    // Data loading related
    case 'orders/loading':
      return state.toggleLoading(action.data);
    case 'orders/loaded_with_success':
      return state.setColumnsAndDatas(action);
    case 'orders/not_loaded_has_error':
      return state.setError(action.error);

      // Add
    case 'orders/request_save':
      return state.toggleOnSaving();
    case 'orders/store':
      return state.addDataToSave(action.data);
    case 'orders/store_new_row':
      return state.storeNewRow(action.data);

    case 'orders/store_updated_row':
      return state.storeUpdatedRow(action.data);

      // Delete
    case 'orders/delete_row':
      return state.deleteOneData(action.id);

    case 'orders/toggleModal':
      return state.toggleModal(action.data);

    default:
      return state;
  }
};

const computePartner = (data, partnerList) => {
  const _row = cloneDeep(data);
  _row.values.ordersId = `#${_row.identifier || 0}`;
  const partner = pick(keyBy(partnerList, '_id'), _row.values.partner);
  _row.values._partner = { name: partner[_row.values.partner]?.name };
  _row.values._id = data._id;
  return _row;
};

export const modalToggle = (value) => ({
  type: 'orders/toggleModal',
  data: value,
});

export const save = (values) => (dispatchEvent, getState) => {
  dispatchEvent({ type: 'orders/request_save', data: null });

  return add('orders', { values }, (err, data) => {
    if (err) {
      return dispatchEvent({ type: 'orders/not_loaded_has_error', err });
    }

    const { partnerList } = getState().Orders;

    return dispatchEvent({ type: 'orders/store_new_row', data: computePartner(data, partnerList) });
  });
};

export const load = (model, offset, filters = null) => (dispatch) => {
  dispatch({ type: `${model}/loading`, data: true });
  _getOrdersDatasAndColumns({
    model, offset, filters, partner: userObj?.user?.partner?._id,
  }, (error, res, columns, driverList) => {
    if (error) {
      return dispatch({ type: `${model}/not_loaded_has_error`, error });
    }

    const {
      results, partnerList, orderTakers, totalRows,
    } = (res.data || {});

    const dataWithIds = results.map((row) => computePartner(row, partnerList));

    return dispatch({
      type: `${model}/loaded_with_success`,
      datas: dataWithIds,
      partnerList,
      orderTakers,
      driverList,
      columns: columns.data,
      totalRows,
    });
  });
};

export const loadAccounting = (offset, partner, { startDate, endDate }) => (dispatch) => {
  dispatch({ type: 'orders/loading', data: true });
  _getAccountingDatasAndColumns({
    model: 'accounting', offset, partner: partner || userObj?.user?.partner?._id, startDate, endDate,
  }, (error, res, columns, driverList) => {
    if (error) {
      return dispatch({ type: 'orders/not_loaded_has_error', error });
    }

    const {
      results, partnerList, orderTakers, totalRows,
    } = (res.data || {});

    const dataWithIds = results.map((row) => computePartner(row, partnerList));

    return dispatch({
      type: 'orders/loaded_with_success',
      datas: dataWithIds,
      partnerList,
      orderTakers,
      driverList,
      columns: columns.data,
      totalRows,
    });
  });
};

export const update = (id, values) => (dispatchEvent, getState) => {
  dispatchEvent({ type: 'orders/request_save', data: null });

  apiUpdate('orders', id, { values }, (err, data) => {
    if (err) {
      return dispatchEvent({ type: 'orders/not_loaded_has_error', err });
    }
    const { partnerList } = getState().Orders;
    const _row = computePartner(data, partnerList);
    return dispatchEvent({ type: 'orders/store_updated_row', data: { id: _row._id, values: _row.values } });
  });
};
export default Orders;
