import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { update, store } from '../../redux/ActionsAndReducers/BaseFunction';

import BaseDetails from './BaseDetails';

const SpecialtyDetails = ({
  data, columns, _updateSpecialty, _storeDataToSave, forCreate,
}) => (
  <BaseDetails
    data={data}
    columns={columns}
    update={_updateSpecialty}
    store={_storeDataToSave}
    forCreate={forCreate}
    keyId="specialtiesId"
    model="specialties"
  />
);

SpecialtyDetails.defaultProps = {
  columns: [],
  forCreate: false,
};

SpecialtyDetails.propTypes = {
  data: PropTypes.shape({}).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  _updateSpecialty: PropTypes.func.isRequired,
  _storeDataToSave: PropTypes.func.isRequired,
  forCreate: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  _updateSpecialty: (id, key, value) => dispatch(update('specialties', id, key, value)),
  _storeDataToSave: (id, key, value) => dispatch(store('specialties', id, key, value)),
});


export default connect(null, mapDispatchToProps)(SpecialtyDetails);
