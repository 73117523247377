/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import Steps from 'antd/es/steps';
import Select from 'antd/es/select';
import Modal from 'antd/es/modal';
import Tag from 'antd/es/tag';
import { connect } from 'react-redux';
import {
  get, cloneDeep, pick, keyBy, difference,
} from 'lodash-es';
import moment from 'moment';
import TextArea from 'antd/es/input/TextArea';
import localStorage from 'local-storage';
import { save, update } from '../../../redux/ActionsAndReducers/Orders';
import ClientDetails from './ClientDetails';
import PackageDetails from './PackageDetails';
import TotalDetails from './TotalDetails';
import Activity from './Activity';

const { Step } = Steps;
const { Option } = Select;
const { confirm } = Modal;

const userObj = localStorage.get('user_object');

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    md: { span: 12 },
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 12 },
  },
};


const status = {
  Placed: 0,
  Accepted: 1,
  Picked: 2,
  Ongoing: 3,
  Delivered: 4,
};

const Header = ({
  form, editable, stat, setStat, data = {},
}) => (
  <Row style={{ borderBottom: '1px solid #e8e8e8', marginBottom: '5px' }}>
    <Col xs={24}>
      <Col md={4} xs={24}>
        <h3>
Order ID :
          {' '}
          {get(data, 'ordersId', '')}
        </h3>
      </Col>
      <Col md={18} xs={24}>
        {stat === 'Rejected' ? (
          <>
            <Tag color="red">Rejected</Tag>
            <Form.Item>
              {
              form.getFieldDecorator('status', {
                initialValue: stat,
              })(
                <Input hidden />,
              )
}

            </Form.Item>

          </>
        )
          : (
            <Form.Item>
              {
            form.getFieldDecorator('status', {
              initialValue: stat,
            })(
              <Input hidden />,
            )
      }
              <Steps current={status[stat]} className="custom-step" size="small">
                <Step title="Placed" icon={stat === 'Placed' ? <Icon type="check-circle" theme="twoTone" twoToneColor="#91d5ff" /> : null} />
                <Step onClick={() => (editable && stat === 'Placed' ? setStat('Accepted') : null)} title="Accepted" icon={stat === 'Accepted' ? <Icon type="check-circle" theme="twoTone" twoToneColor="#d3adf7" /> : null} />
                <Step onClick={() => (editable && stat === 'Accepted' ? setStat('Picked') : null)} title="Picked" icon={stat === 'Picked' ? <Icon type="check-circle" theme="twoTone" twoToneColor="#13c2c2" /> : null} />
                <Step onClick={() => (editable && stat === 'Picked' ? setStat('Ongoing') : null)} title="Ongoing" icon={stat === 'Ongoing' ? <Icon type="check-circle" theme="twoTone" twoToneColor="#fa541c" /> : null} />
                <Step onClick={() => (editable && stat === 'Ongoing' ? setStat('Delivered') : null)} title="Delivered" icon={stat === 'Delivered' ? <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /> : null} />
              </Steps>
            </Form.Item>
          )}
      </Col>
    </Col>
  </Row>
);

const Comment = ({ form, data = {}, disabled }) => (
  <Row>
    <Col xs={24}>
      <Col offset={2} xs={22}>
        <Form.Item
          label="Comment"
        >
          {
            form.getFieldDecorator('comment', {
              initialValue: get(data, 'comment', ''),
            })(
              <TextArea className="form-field" disabled={disabled} />,
            )
      }
        </Form.Item>
      </Col>
    </Col>
  </Row>
);

const PackageDimension = ({ form, data = {}, disabled }) => (
  <Row className="row-dimension">
    <Col xs={24}>
      <Col offset={2} xs={5}>
        <Form.Item
          label="Length"
        >
          {
            form.getFieldDecorator('length', {
              initialValue: get(data, 'length', 0),
            })(
              <Input type="number" className="form-field" disabled={disabled} />,
            )
      }
        </Form.Item>
      </Col>
      <Col xs={5}>
        <Form.Item
          label="Width"
        >
          {
            form.getFieldDecorator('width', {
              initialValue: get(data, 'width', 0),
            })(
              <Input type="number" className="form-field" disabled={disabled} />,
            )
      }
        </Form.Item>
      </Col>
      <Col xs={5}>
        <Form.Item
          label="Height"
        >
          {
            form.getFieldDecorator('height', {
              initialValue: get(data, 'height', 0),
            })(
              <Input type="number" className="form-field" disabled={disabled} />,
            )
      }
        </Form.Item>
      </Col>
      <Col xs={5}>
        <Form.Item
          label="Weight"
        >
          {
            form.getFieldDecorator('weight', {
              initialValue: get(data, 'weight', 0),
            })(
              <Input type="number" className="form-field" disabled={disabled} />,
            )
      }
        </Form.Item>
      </Col>
    </Col>
  </Row>
);

const Footer = ({
  form, onSaving, cancel, forCreate, data = {}, partnerList, orderTakers, setStat, driverList, disabled,
}) => (
  <Row gutter={8} style={{ borderTop: '1px solid lightgrey' }}>
    <Col xs={24}>
      <Col lg={18} xs={24}>
        <Col xs={24}>
          <Col lg={8} xs={24}>
            {forCreate ? null
              : (
                <Form.Item
                  label="Partner"
                  style={{ float: 'left' }}
                  {...formItemLayout}
                >
                  {
              form.getFieldDecorator('partner', {
                initialValue: get(data, 'partner', ''),
              })(
                <Select style={{ width: 150 }}>
                  {(partnerList || []).map((partner) => (
                    <Option key={`$.opt.${partner._id}`} value={partner._id}>{partner.name}</Option>
                  ))}
                </Select>,
              )
          }
                </Form.Item>
              )}
          </Col>
          <Col lg={8} xs={24}>
            {forCreate ? null
              : (
                <Form.Item
                  label="Driver"
                  style={{ float: 'left' }}
                  {...formItemLayout}
                >
                  {
              form.getFieldDecorator('driver', {
                initialValue: get(data, 'driver', ''),
              })(
                <Select style={{ width: 150 }}>
                  {(driverList || []).map((user) => (
                    <Option key={`$.opt.${user.entity_id}`} value={user.first_name}>{user.first_name}</Option>
                  ))}
                </Select>,
              )
          }
                </Form.Item>
              ) }
          </Col>
          <Col lg={8} xs={24}>
            {forCreate ? null
              : (
                <Form.Item
                  label="Order Taker"
                  style={{ float: 'left' }}
                  {...formItemLayout}
                >
                  {
              form.getFieldDecorator('orderTaker', {
                initialValue: get(data, 'orderTaker', ''),
              })(
                <Select style={{ width: 150 }}>
                  {(orderTakers || []).map((user) => (
                    <Option key={`$.opt.${user._id}`} value={user.username}>{user.username}</Option>
                  ))}
                </Select>,
              )
          }
                </Form.Item>
              )}
          </Col>
        </Col>
      </Col>
      <Col lg={6} xs={24}>
        <Col xs={24}>
          <Col lg={6} xs={24}>
            {forCreate ? null
              : (
                <Form.Item style={{ textAlign: 'right' }}>
                  <Button
                    disabled={disabled}
                    type="danger"
                    onClick={() => {
                      confirm({
                        title: 'Are you sure to reject this order ?',
                        okType: 'danger',
                        onOk() {
                          setStat('Rejected');
                        },
                        okText: 'Yes',
                        cancelText: 'No',
                      });
                    }}
                  >
Reject

                  </Button>
                </Form.Item>
              ) }
          </Col>
          <Col lg={18} xs={24}>
            <Form.Item style={{ float: 'right' }}>
              <Button style={{ marginRight: 5 }} type="primary" htmlType="submit" loading={onSaving} disabled={disabled}>Save</Button>
              <Button onClick={() => cancel()}>Cancel</Button>
            </Form.Item>
          </Col>
        </Col>
      </Col>
    </Col>
  </Row>
);

const OrderDetails = ({
  data, forCreate, form, partner, _storeDataToSave, _updateDataToSave,
  onSaving, cancel, partnerList, orderTakers, driverList, onlyTotal,
}) => {
  const [stat, setStat] = useState(get(data, 'status', 'Placed'));
  const [subTotal, setSubTotal] = useState(get(data, 'subTotal', 0));
  const [disabled, setDisabled] = useState(false);
  const activities = get(data, 'activities', []);

  useEffect(() => {
    form.setFieldsValue({ status: stat });
    if (partner && stat !== 'Placed') {
      setDisabled(true);
    }
  }, [stat]);

  if (onlyTotal) {
    return (
      <TotalDetails form={form} data={data} disabled={disabled} subTotal={subTotal} />
    );
  }
  return (
    <>

      <Form
        className="form-order-view"
        onSubmit={(e) => {
          e.preventDefault();

          form.validateFields(async (errors, values) => {
            if (errors) {
              return false;
            }

            const datas = { items: {}, activities };
            const now = moment().format('DD-MM-YYYY HH:mm');

            // eslint-disable-next-line no-restricted-syntax
            for (const [key, value] of Object.entries(values)) {
              if (RegExp('referenceId').test(key) || RegExp('itemName').test(key) || RegExp('price').test(key) || RegExp('itemStatus').test(key) || RegExp('quantity').test(key)) {
                const split = key.split('_');
                if (!datas.items[split[1]]) {
                  datas.items[split[1]] = {};
                }
                datas.items[split[1]][split[0]] = value;

                if (get(data, ['items', split[1], split[0]]) != datas.items[split[1]][split[0]]) {
                  datas.activities.push({
                    user: get(userObj, 'user.username'), date: now, key: `${get(datas, ['items', split[1], 'referenceId'])} : ${split[0]}`, value,
                  });
                }
              } else {
                datas[key] = value;
              }
              if (['orderDeliveryDate', 'deliveryTimeFrom', 'deliveryTimeTo', 'pickUpDateTime'].includes(key)) {
                const v = moment.utc(get(data, key)).local().format('DD-MM-YYYY HH:mm');
                const v2 = cloneDeep(value).format('DD-MM-YYYY HH:mm');
                if (v != v2) {
                  datas.activities.push({
                    user: get(userObj, 'user.username'), date: now, key, value: v2,
                  });
                }
              } else if (get(data, key) != datas[key]) {
                let v = value;
                if (key === 'partner') {
                  const p = pick(keyBy(partnerList, '_id'), datas[key]);
                  v = p[datas[key]]?.name;
                }
                datas.activities.push({
                  user: get(userObj, 'user.username'), date: now, key, value: v,
                });
              }
            }

            datas.partner = datas.partner || partner;
            datas.status = datas.status || 'Placed';

            if (forCreate) {
              datas.activities = [{
                user: get(userObj, 'user.username'), date: now, key: 'create', value: 'create',
              }];
              datas.orderDate = moment();
              _storeDataToSave(datas);
            } else {
              const oldKeys = Object.keys(data.items);
              const currentKeys = Object.keys(datas.items);
              const diff = difference(oldKeys, currentKeys);

              // Deleted item
              if (diff.length > 0 && currentKeys.length < oldKeys.length) {
                datas.activities.push({
                  user: get(userObj, 'user.username'), date: now, key: get(data.items, [diff[0], 'referenceId']), value: 'Deleted',
                });
              }

              datas.id = data._id;
              datas.orderDate = data.orderDate;
              confirm({
                title: 'Are you sure to save changes ?',
                onOk() {
                  _updateDataToSave(data._id, datas);
                },
                okText: 'Yes',
                cancelText: 'No',
              });
            }

            return true;
          }, { force: true });
        }}
      >

        <Row gutter={18}>
          <Col xs={24}>
            <Col lg={forCreate ? 24 : 18} xs={24} style={{ borderRight: '1px solid lightgrey !important' }}>
              {forCreate ? null
                : <Header form={form} data={data} editable={!partner && stat !== 'Delivered'} stat={stat} setStat={setStat} />}
              <ClientDetails form={form} data={data} disabled={disabled} />
              <PackageDetails form={form} data={data} disabled={disabled} setSubTotal={setSubTotal} disabledStatus={!['Delivered', 'Ongoing'].includes(stat)} status={stat} />
              <PackageDimension form={form} data={data} disabled={disabled}/>
              <Comment form={form} data={data} disabled={disabled} />
              <TotalDetails form={form} data={data} disabled={disabled} subTotal={subTotal} />
              <Row>
                <Col xs={24} />
              </Row>
              <Footer
                form={form}
                data={data}
                onSaving={onSaving}
                cancel={cancel}
                forCreate={forCreate || partner}
                partnerList={partnerList}
                orderTakers={orderTakers}
                driverList={driverList}
                stat={stat}
                setStat={setStat}
                disabled={disabled || data.status === 'Delivered'}
              />
            </Col>
            {forCreate ? null
              : (
                <Col lg={6} xs={24}>
                  <Activity activities={activities} />
                </Col>
              )}

          </Col>
        </Row>
      </Form>
    </>
  );
};

OrderDetails.defaultProps = {
  forCreate: false,
};

OrderDetails.propTypes = {
  data: PropTypes.shape({}).isRequired,
  _storeDataToSave: PropTypes.func.isRequired,
  _updateDataToSave: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  forCreate: PropTypes.bool,
  onSaving: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ Orders }) => ({
  onSaving: Orders.onSaving,
  partnerList: Orders.partnerList,
  orderTakers: Orders.orderTakers,
  driverList: Orders.driverList,
});

const mapDispatchToProps = (dispatch) => ({
  _storeDataToSave: (values) => dispatch(save(values)),
  _updateDataToSave: (id, values) => dispatch(update(id, values)),
});


export default Form.create({ name: 'Order_form', id: 'order-view' })(
  connect(mapStateToProps, mapDispatchToProps)(OrderDetails),
);
