import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import { load, deleteData } from '../../redux/ActionsAndReducers/BaseFunction';
import MenuTable from '../../components/Table/MenuTable';
import { notifyError } from '../../helpers/Notify';
import MenuDetails from '../../components/Details/MenuDetails';

class Menus extends React.Component {
  componentDidMount() {
    const {
      _loadmenus, menus, _loadrestaurants, restaurants, specialties, _loadspecialties,
    } = this.props;
    // if ((restaurants || []).length === 0) { _loadrestaurants(); }
    // if ((specialties || []).length === 0) { _loadspecialties(); }
    _loadmenus(0);
  }

  render() {
    const {
      menus, columns, isLoading, err, _delete, totalRows, _loadmenus,
    } = this.props;
    if (err) {
      notifyError('Error on fetching datas');
    }

    // eslint-disable-next-line react/prop-types
    const colsValues = columns.length === 0 ? [] : columns[0].values;
    return (
      <MenuTable
        dataSource={menus.map((res) => res.values)}
        columns={colsValues}
        loading={isLoading}
        model="menus"
        deleteFunc={_delete}
        totalRows={totalRows}
        loadTargetPage={_loadmenus}
        addBtn
        DetailsComponent={MenuDetails}
      />
    );
  }
}

Menus.defaultProps = {
  err: null,
};

Menus.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  err: PropTypes.shape({}),
  menus: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  restaurants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  specialties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalRows: PropTypes.number.isRequired,

  _loadmenus: PropTypes.func.isRequired,
  _delete: PropTypes.func.isRequired,
  _loadrestaurants: PropTypes.func.isRequired,
  _loadspecialties: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  _loadmenus: (offset, filter) => dispatch(load('menus', offset, filter)),
  _delete: (id) => dispatch(deleteData('menus', id)),
  _loadrestaurants: () => dispatch(load('restaurants')),
  _loadspecialties: () => dispatch(load('specialties')),
});

const mapStateToProps = (state) => ({
  isLoading: state.Menus.loading,
  err: state.Menus.error,
  menus: state.Menus.datas,
  totalRows: state.Menus.totalRows,
  columns: state.Menus.columns,
  restaurants: state.Restaurants.datas,
  specialties: state.Specialties.datas,
});

export default connect(mapStateToProps, mapDispatchToProps)(Menus);
