import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import allReducers from './ActionsAndReducers';

const configureStore = () => {
  const middlewares = [thunk];
  if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
  }
  const middlewaresEnhancers = applyMiddleware(...middlewares);
  return createStore(combineReducers(allReducers), middlewaresEnhancers);
};

export default configureStore;
