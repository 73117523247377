import React from 'react';
import PropTypes from 'prop-types';
import { find, merge } from 'lodash-es';

import Render from '../../helpers/OrderRenderers/Render';

import './styles.css';

const composeFields = (columns) => {
  const defaultFields = {};
  (columns || []).forEach((col) => {
    merge(defaultFields, { [col.dataIndex]: '' });
  });

  return defaultFields;
};

const BaseDetails = ({
  data, columns, update, store, forCreate, keyId, model,
}) => {
  const datas = Object.entries(merge(composeFields(columns), data))
    // eslint-disable-next-line no-unused-vars
    .filter(([key, value]) => key !== '_id' && key !== keyId)
    .map(([key, value]) => {
    // find the column config
      const config = find(columns, (c) => c.dataIndex === key);
      if (!config) {
        return null;
      }
      return Render(
        config.type,
        {
          label: config.title,
          value,
          id: key,
          editable: key !== keyId,
          options: config.options || [],
          idInDb: data._id,
          model,
        },
        forCreate ? store : update,
      );
    });

  return (<div style={{ padding: '24px' }}>{datas.filter((c) => !!c)}</div>);
};

BaseDetails.defaultProps = {
  columns: [],
  forCreate: false,
};

BaseDetails.propTypes = {
  data: PropTypes.shape({}).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  update: PropTypes.func.isRequired,
  store: PropTypes.func.isRequired,
  forCreate: PropTypes.bool,
  keyId: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
};

export default BaseDetails;
