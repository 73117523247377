import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import { load, deleteData } from '../../redux/ActionsAndReducers/BaseFunction';
import SpecialtyTable from '../../components/Table/SpecialtyTable';
import { notifyError } from '../../helpers/Notify';
import SpecialtyDetails from '../../components/Details/SpecialtyDetails';

class Specialty extends React.Component {
  componentDidMount() {
    const { _loadspecialties } = this.props;
    _loadspecialties(0);
  }

  render() {
    const {
      specialties, columns, isLoading, err, _delete, totalRows, _loadspecialties,
    } = this.props;
    if (err) {
      notifyError('Error on fetching datas');
    }

    // eslint-disable-next-line react/prop-types
    const colsValues = columns.length === 0 ? [] : columns[0].values;
    return (
      <SpecialtyTable
        dataSource={specialties.map((res) => res.values)}
        columns={colsValues}
        loading={isLoading}
        model="specialties"
        deleteFunc={_delete}
        totalRows={totalRows}
        loadTargetPage={_loadspecialties}
        addBtn
        DetailsComponent={SpecialtyDetails}
      />
    );
  }
}

Specialty.defaultProps = {
  err: null,
};

Specialty.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  err: PropTypes.shape({}),
  specialties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalRows: PropTypes.number.isRequired,

  _loadspecialties: PropTypes.func.isRequired,
  _delete: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  _loadspecialties: (offset) => dispatch(load('specialties', offset)),
  _delete: (id) => dispatch(deleteData('specialties', id)),
});

const mapStateToProps = (state) => ({
  isLoading: state.Specialties.loading,
  err: state.Specialties.error,
  specialties: state.Specialties.datas,
  columns: state.Specialties.columns,
  totalRows: state.Specialties.totalRows,
});

export default connect(mapStateToProps, mapDispatchToProps)(Specialty);
