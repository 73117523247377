import React from 'react';
import Timeline from 'antd/es/timeline';
import Tag from 'antd/es/tag';
import { groupBy, get } from 'lodash-es';
import Divider from 'antd/es/divider';

const MapKeys = {
  orderDeliveryDate: 'Order Delivery Date',
  clientName: 'Customer Name',
  deliveryTimeFrom: 'Delivery Time From',
  deliveryTimeTo: 'Delivery Time To',
  pickUpDateTime: 'Pick Up Date Time',
  subTotal: 'SubTotal',
  subTotalAlreadyPaid: 'Already Paid',
  deliveryFee: 'Delivery Fee',
  deliveryFeePaidByPartner: 'Paid By Partner',
  deliveryFeeToCollect: 'Delivery Fee To Collect',
  totalToCollect: 'Total To Collect',
  subTotalToCollect: 'SubTotal To Collect',
  orderTaker: 'Order Taker',
};

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const renderActivity = (activities) => {
  const timelines = [];
  const colors = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [date, _activities] of Object.entries(activities)) {
    const color = get(colors, date, getRandomColor());
    // eslint-disable-next-line no-param-reassign
    colors[date] = color;
    timelines.push(
      <Timeline.Item color={color} key={color}>
        <h5>{date}</h5>
        {
  _activities.map((act) => (act.key === 'create' ? (
    <p key={color + act.user}>
      <Tag>
        {act.user}
      </Tag>
      {' '}
created order.
    </p>
  ) : (
    <p key={color + act.key}>
      <Tag>
        {act.user}
      </Tag>
changed
      {' '}
      <span style={{ fontStyle: 'italic' }}>{MapKeys[act.key] || act.key}</span>

      {' '}
to
      {' '}
      <span style={{ fontStyle: 'italic' }}>{act.value}</span>
    </p>
  )))
        }
      </Timeline.Item>,
    );
  }
  return timelines;
};

const Activity = ({ activities }) => {
  const groupByDate = groupBy(activities, 'date');
  return (
    <>
      <h4>Activities</h4>
      <Divider />
      <div className="custom-scroll" style={{ maxHeight: 790, overflowY: 'auto' }}>
        <Timeline style={{ paddingTop: 12 }}>
          {renderActivity(groupByDate)}
        </Timeline>

      </div>
    </>
  );
};

export default Activity;
