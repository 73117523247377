/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import TimePicker from 'antd/es/time-picker';
import DatePicker from 'antd/es/date-picker';
import { get } from 'lodash-es';
import moment from 'moment';

const ClientDetails = ({ form, data = {}, disabled }) => (
  <>
    <Row gutter={8}>
      <Col xs={24}>
        <Row>
          <Col md={2} xs={6}>
            <Icon
              type="user"
              style={{
                background: '#E24A4B',
                color: 'white',
                padding: '1rem',
                borderRadius: '1rem',
                marginBottom: '0.5rem',
              }}
            />
          </Col>
          <Col md={14} xs={18}>
            <h4 style={{ paddingLeft: '1%', fontWeight: 'bolder', paddingTop: '8px' }}>Customer Details</h4>
          </Col>
          <Col md={8} xs={18}>
            <Form.Item
              name="Order delivery date"
            >
              {
                form.getFieldDecorator('orderDeliveryDate', {
                  rules: [{ required: true, message: 'Required' }],
                  initialValue: data.orderDeliveryDate ? moment.utc(data.orderDeliveryDate).local() : null,
                })(
                  <DatePicker
                    format="DD-MM-YYYY"
                    style={{ width: '95%' }}
                    placeholder="Order Delivery Date"
                    disabledDate={(current) => current < moment() /* .subtract(1, 'days') */}
                    className="form-field"
                    disabled={disabled}
                  />,
                )
            }
            </Form.Item>
          </Col>
        </Row>
        <Col
          offset={2}
          md={22}
        // style={{ borderBottom: '1px solid coral', marginBottom: '1%' }}
        >
          <Col xs={24}>
            <Col xs={24} md={12}>
              <Form.Item
                name="Name"
              >
                {
              form.getFieldDecorator('clientName', {
                rules: [{ required: true, message: 'Name is required' }],
                initialValue: get(data, 'clientName', ''),
              })(
                <Input
                  prefix={<Icon type="user" />}
                  placeholder="Name"
                  className="form-field"
                  disabled={disabled}
                />,
              )
        }
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="Email"
              >
                {
          form.getFieldDecorator('email', {
            rules: [{ required: false, message: 'Email is required' }, { type: 'email', message: 'Email is not correct' }],
            initialValue: get(data, 'email', ''),
          })(
            <Input
              prefix={<Icon type="mail" />}
              placeholder="Email"
              className="form-field"
              disabled={disabled}
            />,
          )
        }
              </Form.Item>
            </Col>
          </Col>

          <Col xs={24}>
            <Col xs={24} md={12}>
              <Form.Item
                name="Address"
              >
                {
          form.getFieldDecorator('address', {
            rules: [{ required: true, message: 'Please input an address' }],
            initialValue: get(data, 'address', ''),
          })(
            <Input
              prefix={<Icon type="home" />}
              placeholder="Address"
              className="form-field"
              disabled={disabled}
            />,
          )
        }
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name="Available Phone Number"
              >
                {
                form.getFieldDecorator('phoneNumber', {
                  rules: [{ required: true, message: 'Phone number is required' }],
                  initialValue: get(data, 'phoneNumber', ''),
                })(
                  <Input
                    prefix={<Icon type="phone" />}
                    placeholder="Available Phone Number"
                    disabled={disabled}
                  />,
                )
            }
              </Form.Item>
            </Col>

          </Col>
          <Col xs={24}>
            <Col xs={24} md={12}>
              <Form.Item
                name="Landmark"
              >
                {
          form.getFieldDecorator('landmark', {
            initialValue: get(data, 'landmark', ''),
          })(
            <Input
              prefix={<Icon type="environment" />}
              placeholder="Landmark"
              disabled={disabled}
            />,
          )
        }
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                name="Delivery Time From"
              >
                {
          form.getFieldDecorator('deliveryTimeFrom', {
            rules: [{ required: true, message: 'Enter time from' }],
            initialValue: data.deliveryTimeFrom ? moment.utc(data.deliveryTimeFrom).local() : null,
          })(
            <TimePicker
              style={{ width: '100%' }}
              placeholder="Delivery time From"
              format="HH:mm"
              className="form-field"
              disabled={disabled}
            />,
          )
        }
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                name="Delivery Time to"
              >
                {
          form.getFieldDecorator('deliveryTimeTo', {
            rules: [{ required: true, message: 'Enter time to' }],
            initialValue: data.deliveryTimeFrom ? moment.utc(data.deliveryTimeTo).local() : null,
          })(
            <TimePicker
              style={{ width: '100%' }}
              placeholder="Delivery time to"
              format="HH:mm"
              className="form-field"
              disabled={disabled}
            />,
          )
        }
              </Form.Item>
            </Col>
          </Col>
        </Col>
      </Col>
    </Row>
  </>
);

export default ClientDetails;
