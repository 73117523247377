/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Icon from 'antd/es/icon';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import DatePicker from 'antd/es/date-picker';
import Select from 'antd/es/select';

import { get, cloneDeep, findIndex } from 'lodash-es';
import moment from 'moment';

const { Option } = Select;

const PackageItem = ({
  keyProp, index, form, item, removeFunc, disabledRemoveFunc, disabled, onPriceChange, disabledStatus, status,
}) => {
  const [itemStatus, setItemStatus] = useState(get(item, 'itemStatus'));

  disabled = disabled || itemStatus;
  return (
    <Row gutter={8} key={keyProp}>
      <Col xs={24}>
        <small style={{ fontWeight: 'bolder', float: 'left' }}>{`#${index + 1}`}</small>
        <Col xs={24} md={3}>
          <Form.Item
            name={`reference_${keyProp}`}
          >
            {
          form.getFieldDecorator(`referenceId_${keyProp}`, {
            rules: [{ required: true, message: 'Reference ID is required' }],
            initialValue: get(item, 'referenceId', ''),
          })(
            <Input
              placeholder="Item reference id"
              className="form-field"
              disabled={disabled}
            />,
          )
        }
          </Form.Item>
        </Col>
        <Col xs={24} md={10}>
          <Form.Item
            name={`item_name_${keyProp}`}
            rules={[
              {
                required: true,
                message: 'Please input an item name',
              },
            ]}
          >
            {
          form.getFieldDecorator(`itemName_${keyProp}`, {
            rules: [{ required: true, message: 'Item name is required' }],
            initialValue: get(item, 'itemName', ''),
          })(
            <Input
              placeholder="Item name"
              className="form-field"
              disabled={disabled}
            />,
          )
        }
          </Form.Item>
        </Col>
        <Col xs={24} md={4}>
          <Form.Item
            name={`price_${keyProp}`}
          >
            {
            form.getFieldDecorator(`price_${keyProp}`, {
              rules: [{ required: true, message: 'Price is required' }, { type: 'number', message: 'Please enter a number' }],
              initialValue: get(item, 'price', 0),
            })(
              <InputNumber min={0} style={{ width: '100%' }} placeholder="Price Ar" className="form-field" disabled={disabled} onChange={(v) => onPriceChange(item.uid, v, item.quantity)} />,
            )
        }
          </Form.Item>
        </Col>
        <Col xs={24} md={2}>
          <Form.Item
            name={`quantity_${keyProp}`}
          >
            {
            form.getFieldDecorator(`quantity_${keyProp}`, {
              rules: [{ required: true, message: 'Quantity is required' }, { type: 'number', message: 'Please enter a number' }],
              initialValue: get(item, 'quantity', 0),
            })(
              <InputNumber min={1} style={{ width: '100%' }} placeholder="Quantity" className="form-field" disabled={disabled} onChange={(v) => onPriceChange(item.uid, item.price, v)} />,
            )
        }
          </Form.Item>
        </Col>
        <Col xs={24} md={4}>
          <Col xs={20}>
            <Form.Item
              name={`itemStatus_${keyProp}`}
            >
              {
            form.getFieldDecorator(`itemStatus_${keyProp}`, {
              rules: [{ required: status === 'Delivered', message: '*Required' }],
              initialValue: itemStatus,
            })(
              <Select
                placeholder="Status"
                disabled={disabled || disabledStatus}
                onChange={(v) => {
                  // const price = v === 'Refused' ? 0 : item.price;
                  setItemStatus(v);
                  onPriceChange(item.uid, item.price, item.quantity, v);
                }}
              >
                <Option key="$.select.status.1" value="Received" alt="Received by customer">Received</Option>
                <Option key="$.select.status.2" value="Refused" alt="Refused by customer">Refused</Option>
              </Select>,
            )
        }
            </Form.Item>

          </Col>
          <Col xs={4}>
            <Button type="danger" icon="delete" style={{ marginTop: 7 }} size="small" disabled={disabledRemoveFunc || disabled} onClick={() => removeFunc('delete', index)} className="form-field" />
          </Col>
        </Col>
      </Col>

    </Row>
  );
};
const PackageDetails = ({
  form, data = {}, disabled, setSubTotal, disabledStatus, status,
}) => {
  const makeId = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const [items, setItems] = useState([]);
  // const [priceItems, setPriceItems] = useState();

  useEffect(() => {
    const _items = get(data, 'items', { temp: { uid: makeId(5) } });

    const elems = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, item] of Object.entries(_items)) {
      item.uid = key || makeId(5);
      item.price = item.price || 0;
      item.quantity = item.quantity || 1;
      elems.push(item);
    }

    /* const elems = Object.values(_items).map((item) => {
      item.uid = item.uid || makeId(5);
      item.price = item.price || 0;
      item.quantity = item.quantity || 1;
      return item;
    }); */
    setItems(elems);
  }, []);

  const handleItems = (action, index = null) => {
    switch (action) {
      case 'delete':
        items.splice(index, 1);
        setItems([...items]);
        break;
      default: setItems([...items, { uid: makeId(5), price: 0, quantity: 1 }]);
    }
  };

  const onPriceChange = (uid, p, q, itemStatus) => {
    const _items = cloneDeep(items);
    const index = findIndex(_items, (item) => item.uid === uid);
    if (index !== -1) {
      _items[index].price = p;
      _items[index].quantity = q;
      _items[index].itemStatus = itemStatus;
    }

    let subTotal = 0;

    _items.forEach((item) => {
      let { price } = item;
      if (item.itemStatus === 'Refused') {
        price = 0;
      }
      subTotal += (price || 0) * (item.quantity || 0);
    });

    setItems(_items);
    setSubTotal(subTotal);
  };

  return (
    <>
      <Row gutter={8}>
        <Col xs={24}>
          <Row>
            <Col md={2} xs={6}>
              <Icon
                type="container"
                style={{
                  background: '#E24A4B',
                  color: 'white',
                  padding: '1rem',
                  borderRadius: '1rem',
                  marginBottom: '0.5rem',
                }}
              />
            </Col>
            <Col md={14} xs={18}>
              <h4 style={{ paddingLeft: '1%', fontWeight: 'bolder', paddingTop: '8px' }}>Package Details</h4>
            </Col>
            <Col md={8} xs={18}>
              <Form.Item
                name="Pick up date time"
              >
                {
                form.getFieldDecorator('pickUpDateTime', {
                  rules: [{ required: true, message: 'Required' }],
                  initialValue: data.pickUpDateTime ? moment.utc(data.pickUpDateTime).local() : null,
                })(
                  <DatePicker
                    showTime={{ showSecond: false }}
                    format="DD-MM-YYYY HH:mm"
                    style={{ width: '95%' }}
                    placeholder="Pick up date time"
                    disabledDate={(current) => current < moment()}
                    className="form-field"
                    disabled={disabled}
                  />,
                )
            }
              </Form.Item>
            </Col>
          </Row>
          <Col offset={2} md={22}>
            <div className="custom-scroll" style={{ maxHeight: 179, overflowY: 'auto' }}>
              {items.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <PackageItem
                  key={`$.package.item.${index}`}
                  keyProp={item.uid}
                  index={index}
                  item={item}
                  form={form}
                  removeFunc={handleItems}
                  disabledRemoveFunc={items.length === 1}
                  disabled={disabled}
                  onPriceChange={onPriceChange}
                  disabledStatus={disabledStatus}
                  status={status}
                />
              ))}
            </div>
            <Button type="dashed" icon="plus" style={{ float: 'right' }} size="small" onClick={handleItems} disabled={disabled}>Add</Button>
          </Col>
        </Col>
      </Row>
    </>
  );
};


export default PackageDetails;
