/* eslint-disable react/jsx-props-no-spreading */
import React, { useReducer } from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import { get, pick } from 'lodash-es';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    md: { span: 12 },
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 12 },
  },
};

const computeNegative = (amount) => (Math.sign(amount) === -1 ? 0 : amount);

const TotalDetails = ({
  form, data = {}, disabled, subTotal,
}) => {
  const reducer = (state, action) => {
    let newState = state;
    switch (action.key) {
      default:
        newState = { ...newState, [action.key]: computeNegative(action.value || 0) };
        newState = { ...newState, total: computeNegative(subTotal || 0) + computeNegative(newState.deliveryFee || 0) };
        newState = { ...newState, subTotalToCollect: computeNegative(subTotal || 0) - computeNegative((subTotal ? newState.subTotalAlreadyPaid : 0) || 0) };
        newState = { ...newState, deliveryFeeToCollect: computeNegative(newState.deliveryFee || 0) - computeNegative(newState.deliveryFeePaidByPartner || 0) };
        newState = { ...newState, totalToCollect: computeNegative(newState.subTotalToCollect || 0) + computeNegative(newState.deliveryFeeToCollect || 0) };
        return newState;
    }
  };


  const [state, dispatch] = useReducer(reducer, {
    subTotal,
    ...pick(data, [
      'subTotalAlreadyPaid',
      'subTotalToCollect',
      'deliveryFee',
      'deliveryFeePaidByPartner',
      'deliveryFeeToCollect',
      'total',
      'totalToPaid',
      'totalToCollect',
    ]),
  });
  const diff = subTotal !== state.subTotal;
  if (diff) {
    dispatch({ key: 'subTotal', value: subTotal });
  }

  return (
    <Col xs={24} style={{ zIndex: 1 }}>
      <Row gutter={8}>
        <Col xs={24}>
          <Row>
            <Col md={2} xs={6} />
            <Col md={3} xs={18}>
              <h4 style={{ paddingLeft: '1%', fontWeight: 'bolder', paddingTop: '8px' }}>SubTotal :</h4>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item
                name="SubTotal"
              >
                {
              form.getFieldDecorator('subTotal', {
                rules: [{ required: true, message: 'Subtotal is required' }, { type: 'number', message: 'Please enter a number' }],
                initialValue: state.subTotal || 0,
              })(
                <InputNumber
                  min={0}
                  style={{ width: '100%' }}
                  // onChange={(value) => dispatch({ key: 'subTotal', value })}
                  className="form-field"
                  disabled
                />,
              )
        }
              </Form.Item>
            </Col>
            <Col md={7} xs={24}>
              <Form.Item
                label="Already Paid  "
                {...formItemLayout}
              >
                {
              form.getFieldDecorator('subTotalAlreadyPaid', {
                rules: [{ type: 'number', message: 'Please enter a number' }],
                initialValue: get(state, 'subTotalAlreadyPaid', 0),
              })(
                <InputNumber min={0} style={{ width: '100%' }} onChange={(value) => dispatch({ key: 'subTotalAlreadyPaid', value })} className="form-field" disabled={disabled} />,
              )
        }
              </Form.Item>
            </Col>
            <Col md={7} xs={24}>
              <Form.Item
                label="To Collect"
                {...formItemLayout}
              >
                {
              form.getFieldDecorator('subTotalToCollect', {
                rules: [{ type: 'number', message: 'Please enter a number' }],
                initialValue: get(state, 'subTotalToCollect', 0),
              })(
                <InputNumber disabled min={0} style={{ width: '100%', color: 'dimgrey' }} className="form-field" />,
              )
        }
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24}>
          <Row>
            <Col md={2} xs={6} />
            <Col md={3} xs={18}>
              <h4 style={{ paddingLeft: '1%', fontWeight: 'bolder', paddingTop: '8px' }}>Delivery Fee :</h4>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item
                name="Delivery fee"
              >
                {
              form.getFieldDecorator('deliveryFee', {
                rules: [{ required: true, message: 'Delivery Fee is required' }, { type: 'number', message: 'Please enter a number' }],
                initialValue: state.deliveryFee || 0,
              })(
                <InputNumber min={0} style={{ width: '100%' }} onChange={(value) => dispatch({ key: 'deliveryFee', value })} className="form-field" disabled={disabled} />,
              )
        }
              </Form.Item>
            </Col>
            <Col md={7} xs={24}>
              <Form.Item
                label="Paid by Partner"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                {...formItemLayout}
              >
                {
              form.getFieldDecorator('deliveryFeePaidByPartner', {
                rules: [{ type: 'number', message: 'Please enter a number' }],
                initialValue: get(state, 'deliveryFeePaidByPartner', 0),
              })(
                <InputNumber min={0} style={{ width: '100%' }} onChange={(value) => dispatch({ key: 'deliveryFeePaidByPartner', value })} className="form-field" disabled={disabled} />,
              )
        }
              </Form.Item>
            </Col>
            <Col md={7} xs={24}>
              <Form.Item
                label="To Collect"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                {...formItemLayout}
              >
                {
              form.getFieldDecorator('deliveryFeeToCollect', {
                rules: [{ type: 'number', message: 'Please enter a number' }],
                initialValue: get(state, 'deliveryFeeToCollect', 0),
              })(
                <InputNumber min={0} style={{ width: '100%', color: 'dimgrey' }} disabled />,
              )
        }
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24}>
          <Row>
            <Col md={2} xs={6} />
            <Col md={3} xs={18}>
              <h4 style={{ paddingLeft: '1%', fontWeight: 'bolder', paddingTop: '8px' }}>Total :</h4>
            </Col>
            <Col md={4} xs={18}>
              <Form.Item
                name="Total"
              >
                {
              form.getFieldDecorator('total', {
                initialValue: get(state, 'total', 0),
              })(
                <Input style={{ width: '100%' }} disabled hidden />,
              )
        }
                <h4>
                  {state.total || get(data, 'total', 0)}
                  {' '}
                  {' '}
                  {' '}
  Ar
                </h4>
              </Form.Item>
            </Col>
            <Col offset={7} md={7} xs={24}>
              <Form.Item
                label="Total to collect"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                {...formItemLayout}
              >
                {
              form.getFieldDecorator('totalToCollect', {
                rules: [{ type: 'number', message: 'Please enter a number' }],
                initialValue: get(state, 'totalToCollect', 0),
              })(
                <Input style={{ width: '100%' }} disabled hidden />,
              )
        }
                <h4>
                  {state.totalToCollect || get(data, 'totalToCollect', 0)}
                  {' '}
                  {' '}
                  {' '}
  Ar
                </h4>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default TotalDetails;
