/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import Input from 'antd/es/input';
import PropTypes from 'prop-types';

import './styles.css';

class EditableTextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      newValue: props.initialValue.value,
    };
    this.changeToEdit = this.changeToEdit.bind(this);
    this.save = this.save.bind(this);
    this.currentInput = null;
  }

  save(e) {
    this.setState({ editMode: false, newValue: e.currentTarget.value });
    const { initialValue, _update } = this.props;
    if (initialValue.value !== e.currentTarget.value) {
      _update(initialValue.idInDb, initialValue.id, e.currentTarget.value);
    }
  }

  changeToEdit() {
    const { editable } = this.props;
    if (!editable) {
      return;
    }

    this.setState({ editMode: true }, () => {
      this.currentInput.focus();
    });
  }

  render() {
    const { editMode, newValue } = this.state;
    const { editable } = this.props;
    const InputData = (
      <Input
        onBlur={(e) => this.save(e)}
        defaultValue={newValue}
        ref={(input) => { this.currentInput = input; }}
      />
    );

    return editMode && editable
      ? InputData
      : (
        <span
          onClick={() => this.changeToEdit()}
          role="button"
          className="clickable-text"
        >
          {newValue}

        </span>
      );
  }
}

EditableTextField.defaultProps = {
  initialValue: '',
  editable: true,
};

EditableTextField.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({})]),
  editable: PropTypes.bool,
  _update: PropTypes.func.isRequired,
};


export default EditableTextField;
