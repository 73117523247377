import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import NotFound404 from '../errors/404';
import DashboardLayout from '../../components/DashboardLayout/Layout';
import Main from './main';
import Accounting from './Accounting';
import Restaurants from './Restaurants';
import Specialties from './Specialty';
import Menu from './Menu';
import Customer from './Customer';
import Partner from './Partner';
import RestrictedComponent from '../../hoc/RestrictedComponent';

const RestrictedMain = () => (<RestrictedComponent Component={Main} />);
const RestrictedAccounting = () => (<RestrictedComponent Component={Accounting} />);
/* const RestrictedRestaurants = () => (<RestrictedComponent Component={Restaurants} />);
const RestrictedMenus = () => (<RestrictedComponent Component={Menu} />);
const RestrictedSpecialties = () => (<RestrictedComponent Component={Specialties} />);
const RestrictedCustomers = () => (<RestrictedComponent Component={Customer} />); */

const routeDashboard = ({ match }) => (
  <DashboardLayout>
    <Switch>
      <Route path={`${match.path}/main`} component={RestrictedMain} />
      <Route path={`${match.path}/accounting`} component={RestrictedAccounting} />
      {/* <Route path={`${match.path}/restaurants`} component={RestrictedRestaurants} />
      <Route path={`${match.path}/menus`} component={RestrictedMenus} />
      <Route path={`${match.path}/specialties`} component={RestrictedSpecialties} />
<Route path={`${match.path}/customers`} component={RestrictedCustomers} /> */}

      <Route component={NotFound404} />
    </Switch>
  </DashboardLayout>
);

routeDashboard.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

export default routeDashboard;
