import React from 'react';
import Card from 'antd/es/card';

import FieldUpdatesFeedback from '../../hoc/FieldUpdatesFeedback';
import EditableTextField from '../../components/EditableTextField/EditableTextField';
import './styles.css';

const Basic = (data, saveFunction) => (
  <Card className="card-details" key={data.id} style={{ position: 'relative', marginBottom: '1em' }}>
    <table className="table-details">
      <tbody>
        <tr>
          <td>{data.label}</td>
          <td>
            <FieldUpdatesFeedback updateKey={data.id} model={data.model}>
              <EditableTextField
                initialValue={data}
                editable={data.editable}
                _update={saveFunction}
              />
            </FieldUpdatesFeedback>
          </td>
        </tr>
      </tbody>
    </table>
  </Card>
);

export default Basic;
