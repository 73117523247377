/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import Select from 'antd/es/select';
import PropTypes from 'prop-types';

const { Option } = Select;

class EditableCategoryField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      // newValue: props.initialValue.value,
      newValue: null,
    };
    this.changeToEdit = this.changeToEdit.bind(this);
    this.save = this.save.bind(this);
    this.currentInput = null;
  }

  save(tempVal) {
    if (!tempVal) return;
    let val = {
      id: tempVal.key,
      name: tempVal.label,
    };
    if (tempVal.key === tempVal.label) {
      val = tempVal.label;
    }

    this.setState({ editMode: false, newValue: val });
    const { _update, initialValue } = this.props;
    if (initialValue.value !== val) {
      _update(initialValue.idInDb, initialValue.id, val);
    }
  }

  changeToEdit() {
    const { editable } = this.props;
    if (!editable) {
      return;
    }

    this.setState({ editMode: true }, () => {
      this.currentInput.focus();
    });
  }

  render() {
    const { editMode, newValue } = this.state;
    const { editable, initialValue } = this.props;
    const InputData = (
      <Select
        labelInValue
        style={{ width: 200 }}
        placeholder={`Select a ${initialValue.label}`}
        onBlur={this.save}
        // defaultValue={newValue}
        ref={(re) => { this.currentInput = re; }}
      >
        {initialValue.options.map((option) => {
          if (option.id !== undefined) {
            return (<Option key={option.id} value={option.id}>{option.name}</Option>);
          }
          return (
            <Option key={option} value={option}>
              {option}
            </Option>
          );
        })}
      </Select>
    );
    const initialVal = initialValue ? initialValue.value.name || initialValue.value : '';
    const value = newValue ? newValue.name || newValue : '';
    return editMode && editable
      ? InputData
      : (
        <span
          onClick={() => this.changeToEdit()}
          role="button"
          className="clickable-text"
        >
          { value || initialVal}

        </span>
      );
  }
}

EditableCategoryField.defaultProps = {
  initialValue: null,
  editable: true,
};

EditableCategoryField.propTypes = {
  initialValue: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    idInDb: PropTypes.string,
    id: PropTypes.string,
  }),
  editable: PropTypes.bool,
  _update: PropTypes.func.isRequired,
};

export default EditableCategoryField;
