import React from 'react';
import Modal from 'antd/es/modal';
import PropTypes from 'prop-types';

import './styles.css';

const FullscreenModal = ({
  visible, cancel, renderContent,
}) => (
  <Modal
    visible={visible}
    okText="Save"
    // okButtonProps={{ disabled: true }}
    // cancelButtonProps={{ disabled: true }}
    onCancel={cancel}
    footer={null}
    // width="100%"
    className="fullscreen-modal"
    destroyOnClose
  >
    {renderContent()}
  </Modal>
);

FullscreenModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  renderContent: PropTypes.func.isRequired,
};

export default FullscreenModal;
