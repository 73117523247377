/* eslint-disable no-param-reassign */
import React from 'react';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Icon from 'antd/es/icon';
import Tag from 'antd/es/tag';
import Modal from 'antd/es/modal';
import { cloneDeep, get } from 'lodash-es';
import update from 'immutability-helper';
import moment from 'moment';

const Confirm = Modal.confirm;

const colors = {
  Placed: 'blue',
  Rejected: 'red',
  Accepted: 'purple',
  Picked: 'cyan', // navy
  Ongoing: 'volcano',
  Delivered: 'green',
};

const composeOrderColumns = (columnProps, filterFunc, model, partnerList, asPartner) => {
  const columns = cloneDeep(columnProps);

  return columns
    .filter((entry) => entry.visible)
    .map((entry, index) => {
      if (index === 0) {
        // eslint-disable-next-line no-param-reassign
        entry = {
          ...entry,
          render: (text, record) => (model === 'accounting' ? (
            <>
              <Tag color={colors[record.status]}>{record.status}</Tag>
              {' '}
              {text}
            </>
          ) : text),
        };
      }

      if (entry.type === 'select' && entry.key === 'status') {
        entry.filters = ['Placed', 'Accepted', 'Picked', 'Ongoing', 'Delivered', 'Rejected'].map((status) => ({ text: status, value: status }));
        entry.filterMultiple = false;
        // eslint-disable-next-line no-param-reassign
        entry = {
          ...entry,
          render: (text, record) => (<Tag color={colors[record.status]}>{record.status}</Tag>),
        };
      }

      if (entry.type === 'select' && entry.key === 'partner') {
        entry.filters = partnerList.map((p) => ({ text: p.name, value: p._id }));
        entry.filterMultiple = false;
        return entry;
      }

      // handle date columns
      if (entry.type === 'date' || entry.type === 'datetime') {
        // eslint-disable-next-line no-param-reassign
        entry = {
          ...entry,
          render: (text) => {
            if (typeof text === 'object') {
              const startText = text ? text.start : '-';
              const endText = text ? text.end : '-';
              return `${startText} - ${endText}`;
            }
            const date = moment.utc(text).local();
            const format = entry.type === 'date' ? 'DD-MM-YYYY' : 'DD-MM-YYYY HH:mm';
            return date.format(format);
          },
        };
      }

      // handle timeSlot columns
      if (entry.type === 'timeSlot') {
        // eslint-disable-next-line no-param-reassign
        entry = {
          ...entry,
          render: (text, record) => {
            const startText = moment.utc(get(record, 'deliveryTimeFrom', '')).local();
            const endText = moment.utc(get(record, 'deliveryTimeTo', '')).local();
            return `${startText.format('HH:mm')} - ${endText.format('HH:mm')}`;
          },
        };
      }

      // handle client columns
      if (entry.type === 'client') {
        // eslint-disable-next-line no-param-reassign
        entry = {
          ...entry,
          render: (text, record) => {
            const name = get(record, 'clientName', '');
            const phoneNumber = get(record, 'phoneNumber', '');
            return `${name} (${phoneNumber})`;
          },
        };
      }

      // handle package columns
      if (entry.type === 'package') {
        // eslint-disable-next-line no-param-reassign
        entry = {
          ...entry,
          render: (text, record) => {
            const items = get(record, 'items', {});

            return (
              <div>
                {
          Object.values(items).map((item) => (
            <div key={`$.list.${item.referenceId}`}>
              <small>
                -
                {' '}
                {item.itemName}
                {' '}
(x
                {item.quantity}
)
              </small>

            </div>
          ))
                }
              </div>
            );
          },
        };
      }

      // handle delete action
      if (entry.type === 'action') {
        // eslint-disable-next-line no-param-reassign
        entry = {
          ...entry,
          render: (text, record) => (
            <Button
              disabled={asPartner && record?.values?.status !== 'Placed'}
              style={{ zIndex: 2 }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (typeof entry.func === 'function') {
                  Confirm({
                    title: 'Delete this order ?',
                    onOk() {
                      entry.func.call(this, record._id);
                    },
                    cancelText: 'Cancel',
                  });
                }
              }}
              type="danger"
              icon="delete"
              size="small"
            />
          ),
        };
      }

      // handle columns with sorters
      if (entry.sortDirections) {
        // eslint-disable-next-line no-param-reassign
        entry = {
          ...entry,
          sorter: (a, b) => a[entry.dataIndex]
            .toLowerCase()
            .localeCompare(b[entry.dataIndex].toLowerCase()),
        };
      }


      if (!entry.searchable) {
        return entry;
      }

      const input = React.createRef();

      // handle columns with search
      return {
        ...entry,
        filterDropdown: ({
        // eslint-disable-next-line react/prop-types
          setSelectedKeys, confirm, clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={input}
              placeholder="Search..."
            // value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => {
                if (filterFunc) {
                  filterFunc([{ dataIndex: `values.${entry.dataIndex}`, search: e.target.value.toLowerCase() }]);
                }
              }}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => {
                filterFunc([{ dataIndex: `values.${entry.dataIndex}`, search: input.current.state.value.toLowerCase() }]);
              }}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
            Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
                input.current.state.value = '';
              }}
              size="small"
              style={{ width: 90 }}
            >
            Reset
            </Button>
          </div>
        ),

        filterIcon: (filtered) => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),

        onFilter: (value, record) => get(record, entry.dataIndex, '')
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),

        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => input.current.select());
          }
        },
      };
    });
};

export const addActionColumn = (columnProps, cb) => {
  const columns = cloneDeep(columnProps);

  return update(columns, {
    $push: [{
      title: 'Action',
      key: 'operation',
      // fixed: 'right',
      width: 100,
      type: 'action',
      visible: true,
      func: cb,
    }],
  });
};

export default composeOrderColumns;
