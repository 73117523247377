/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import localStorage from 'local-storage';
import get from 'lodash-es/get';
import omit from 'lodash-es/omit';
import keyBy from 'lodash-es/keyBy';

// import moment from 'moment';
import sumBy from 'lodash-es/sumBy';
import { loadAccounting, modalToggle, update } from '../../redux/ActionsAndReducers/Orders';
import { deleteData } from '../../redux/ActionsAndReducers/BaseFunction';
import AccountingTable from '../../components/Table/AccountingTable';
import { notifyError } from '../../helpers/Notify';
import OrderDetails from '../../components/Details/Order/OrderDetails';


const userObj = localStorage.get('user_object');

const Accounting = ({
  orders, columns, isLoading, err,
  _delete, totalRows, _loadOrders, shownModal, _modalToggle,
  partnerList, _updateDataToSave,
}) => {
  if (err) {
    notifyError('Error on accounting datas');
  }
  // eslint-disable-next-line react/prop-types
  const asPartner = get(userObj, 'user.role.type', '') === 'partner' ? get(userObj, 'user.partner._id', null) : null;
  const role = get(userObj, 'user.role.type', '');
  let colsValues = columns.length === 0 ? [] : columns[0].values;
  if (asPartner && colsValues.length !== 0) {
    colsValues = Object.values(omit(keyBy(colsValues, 'key'), ['partner', 'orderTaker']));
  }
  return (
    <AccountingTable
      toBePaidByPartner={isLoading ? 0 : (Math.sign(sumBy(orders, (o) => o?.values?.deliveryFeePaidByPartner || 0)) === -1 ? 0 : sumBy(orders, (o) => o?.values?.deliveryFeePaidByPartner || 0))}
      toBePaidByEsakafo={isLoading ? 0 : (Math.sign(sumBy(orders, (o) => o?.values?.subTotalToCollect || 0)) === -1 ? 0 : sumBy(orders, (o) => o?.values?.subTotalToCollect || 0))}
      dataSource={orders.map((res) => res.values)}
      columns={colsValues}
      loading={isLoading}
      model="accounting"
      deleteFunc={_delete}
      totalRows={totalRows}
      loadTargetPage={_loadOrders}
      addBtn={false}
      shownModal={shownModal}
      modalToggle={_modalToggle}
      displayAction={false}
      DetailsComponent={OrderDetails}
      asPartner={asPartner}
      partnerList={partnerList}
      role={role}
      updateDataToSave={_updateDataToSave}
    />
  );
};

Accounting.defaultProps = {
  err: null,
};

Accounting.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  shownModal: PropTypes.bool.isRequired,
  err: PropTypes.shape({}),
  orders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalRows: PropTypes.number.isRequired,
  _delete: PropTypes.func.isRequired,
  _loadOrders: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  _loadOrders: (offset, partner, periode) => dispatch(loadAccounting(offset, partner, periode)),
  _delete: (id) => dispatch(deleteData('orders', id)),
  _modalToggle: (value) => dispatch(modalToggle(value)),
  _updateDataToSave: (id, values) => dispatch(update(id, values)),
});

const mapStateToProps = ({ Orders }) => ({
  isLoading: Orders.loading,
  err: Orders.error,
  orders: Orders.datas,
  columns: Orders.columns,
  totalRows: Orders.totalRows,
  shownModal: Orders.shownModal,
  partnerList: Orders.partnerList,
});

export default connect(mapStateToProps, mapDispatchToProps)(Accounting);
